import React, { useState, useEffect, useRef } from "react";
import { socket } from "../../io";
import "./linea_responsive.css";
import triangulo from "../../assets/venta/triangulo.png";
import logo from "../../assets/logo.png";
import ws from "../../assets/venta/ws1.png";
import { headers, url } from "../../utils/const";
import {
  Stage,
  Layer,
  Rect,
  Text,
  Group,
  Image as KonvaImage,
} from "react-konva";
const rgb = [
  "#0000FF", "#0000FF","#0000FF","#0000FF","#0000FF","#0000FF","#0000FF","#0000FF","#0000FF",
  "#0000FF",
  "#0000FF",
  "#0000FF",
  "#0000FF",
  "#0000FF",
  "#0000FF",
  "#0000FF",
  "#0000FF",
  "#0000FF",
  "#FF9900",
  "#FF9900",
  "#FF9900",
  "#FF6600",
  "#FF3300",
  "#FF0000",
];

const dogs = [
  { num: 1, bg: [], color: "white", stroke: "red" }, // style1
  { num: 2, bg: [], color: "white", stroke: "blue" }, // style2
  { num: 3, bg: [], color: "black", stroke: "gray" }, // style3
  { num: 4, bg: [], color: "white", stroke: "black" }, // style4
  { num: 5, bg: [], color: "black", stroke: "orange" }, // style5
  { num: 6, bg: [], color: "brown", stroke: "brown" }, // style6
];
const bgArray1 = [
  "rgb(255, 240, 240), rgb(255, 155, 155), rgb(255, 72, 72), rgb(255, 0, 0), rgb(248, 0, 0), rgb(240, 0, 0), rgb(236, 0, 0), rgb(232, 0, 0), rgb(242, 0, 0), rgb(255, 36, 36), rgb(249, 61, 61), rgb(217, 125, 125)",
][0]
  .replace(/, r/g, ",,r")
  .split(",,")
  .map((z) => z);
const bgArray6 = ["#fff, #111, #fff, #111, #fff, #111, #fff, #111"][0]
  .replace(/, #/g, ",,#")
  .split(",,")
  .map((z) => z);
const bgArray3 = ["#fff, #fff, #e6e6e6, #fff, #fdfdfd, #fff, #e6e6e6"][0]
  .replace(/, #/g, ",,#")
  .split(",,")
  .map((z) => z);
const bgArray2 = [
  "#a8e3f1, #76f6f4, #56dff1, #25d4f7, #29acf8, #0f9ef6, #76c5f6",
][0]
  .replace(/, #/g, ",,#")
  .split(",,")
  .map((z) => z);
const bgArray5 = [
  "rgb(255, 128, 0), rgb(251, 180, 109), rgb(252, 167, 81), rgb(255, 143, 32), rgb(255, 128, 0), rgb(255, 128, 0), rgb(255, 128, 0), rgb(253, 127, 0), rgb(255, 136, 17), rgb(255, 150, 45), rgb(255, 193, 130), rgb(252, 184, 116)",
][0]
  .replace(/, r/g, ",,r")
  .split(",,")
  .map((z) => z);
const bgArray = [
  "#688a6c, #398242, #177422, #087c16, #00800f, #0d851b, #22862e, #408b48, #658e69",
][0]
  .replace(/, #/g, ",,#")
  .split(",,")
  .map((z) => z);
const bgArray4 = [
  "#ffffff, #ffffff, #383838, #161616, #272727, #101010, #000000, #181818, #151515, #2b2a2a",
][0]
  .replace(/, #/g, ",,#")
  .split(",,")
  .map((z) => z);

bgArray1.forEach((item, index) => {
  dogs[0].bg.push(index / bgArray1.length);
  dogs[0].bg.push(item);
});
bgArray2.forEach((item, index) => {
  dogs[1].bg.push(index / bgArray1.length);
  dogs[1].bg.push(item);
});
bgArray3.forEach((item, index) => {
  dogs[2].bg.push(index / bgArray1.length);
  dogs[2].bg.push(item);
});
bgArray4.forEach((item, index) => {
  dogs[3].bg.push(index / bgArray1.length);
  dogs[3].bg.push(item);
});
bgArray5.forEach((item, index) => {
  dogs[4].bg.push(index / bgArray1.length);
  dogs[4].bg.push(item);
});
bgArray6.forEach((item, index) => {
  dogs[5].bg.push(index / bgArray1.length);
  dogs[5].bg.push(item);
});

const data = [
  ["GANADOR", 1, 2, 3, 4, 5, 6],
  [1, "p1", "p12", "p13", "p14", "p15", "p16"],
  [2, "p21", "p2", "p23", "p24", "p25", "p26"],
  [3, "p31", "p32", "p3", "p34", "p35", "p36"],
  [4, "p41", "p42", "p43", "p4", "p45", "p46"],
  [5, "p51", "p52", "p53", "p54", "p5", "p56"],
  [6, "p61", "p62", "p63", "p64", "p65", "p6"],
];

function Lines(number) {
  const [ready, setReady] = useState(false);
  const stageRef = useRef(null);
  const [triang, setTriang] = useState();
  const [sizeRef, setSizeRef] = useState(1024);
  const [msg, setMsg] = useState("");
  const [classMsg, setClassMsg] = useState("white");
  const [betted, setBetted] = useState(25);
  const [betThisPlayer, setBetThisPlayer] = useState({
    dog: "",
    line: "",
    pay: 0,
  });
  const [show, setShow] = useState(false);
  const [lineForPaleMan, setLineForPaleMan] = useState();
  const [lineForPaleWoman, setLineForPaleWoman] = useState();
  localStorage.setItem("reload", true);
  const [result, setResult] = useState(false);
  const [lastWinerJpGral, setLastWinerJpGral] = useState();
  const [winner, setWinner] = useState({
    first: "",
    second: "",
    lineFirst: 0,
    linePale: 0,
  });
  const [time, setTime] = useState();
  const [jpg, setJpg] = useState();
  const [jpl, setJpl] = useState();
  const [jpc, setJpc] = useState();
  const [lines, SetLines] = useState();
  const [run, SetRun] = useState();
  const [code, SetCode] = useState();
  const [history, setHistory] = useState();
  const [manDog, setManDog] = useState();
  const [womanDog, setWomanDog] = useState();
  const [manPaleDog, setPaleManDog] = useState();
  const [womanPaleDog, setPaleWomanDog] = useState();
  const [lineUp, setLineUp] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [imgLogo, setImgLogo] = useState();
  const [trip, setTrip] = useState(false);
  const [widthCanvas, setWidthCanvas] = useState(window.innerWidth * 0.99);
  const [heightCanvas, setHeightCanvas] = useState(window.innerHeight * 0.99);
  const [wsImg, setWsimg] = useState();
  const [tripOption, setTripOption] = useState("COLOCADA");
  const [dimenssion, setDimenssion] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setDimenssion({ width: window.innerWidth, height: window.innerHeight });
      window.location.reload();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dimenssion]);
  useEffect(() => {
    const img = new window.Image();
    img.src = logo;
    img.onload = () => setImgLogo(img);
  }, []);
  useEffect(() => {
    const img = new window.Image();
    img.src = ws;
    img.onload = () => setWsimg(img);
  }, []);
  useEffect(() => {
    const img = new window.Image();
    img.src = triangulo;
    img.onload = () => setTriang(img);
  }, []);
  useEffect(() => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    setSizeRef(w < h ? w : h);
  }, [sizeRef]);
  useEffect(() => {
    const handleResize = () => {
      const w = window.innerWidth;
      const h = window.innerHeight;
      setSizeRef(w < h ? w : h);
      setWidthCanvas(window.innerWidth * 0.99);
      setHeightCanvas(window.innerHeight * 0.99);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    // Emitir 'active-res' al conectar
    socket.emit("active-res", { point: localStorage.getItem("point") });

    // Handler para el evento 'run'
    const handleRun = (runReq) => {
      SetRun(runReq);

      // Actualizar el código si existe
      if (runReq?.run?.code) {
        SetCode(runReq.run.code.toString().padStart(4, "0"));
      }

      // Actualizar historial si es diferente
      if (runReq?.history && runReq.history !== history) {
        setHistory(runReq.history);
      }

      // Actualizar líneas si existen
      if (runReq?.run?.line) {
        SetLines(runReq.run.line);
        const line = runReq.run.line;
        setReady(true);

        if (!lineUp) {
          // Asignación de estilo para macho y hembra
          const lineArray = [
            { label: "p1", value: line.d.p1 },
            { label: "p2", value: line.d.p2 },
            { label: "p3", value: line.d.p3 },
            { label: "p4", value: line.d.p4 },
            { label: "p5", value: line.d.p5 },
            { label: "p6", value: line.d.p6 },
          ].sort((a, b) => a.value - b.value);

          const macho = lineArray[0].label;
          const woman = lineArray[5].label;
          const paleMan = `${lineArray[1].label}${lineArray[0].label
            .split("")
            .at(-1)}`;
          const paleWoman = `${lineArray[5].label}${lineArray[4].label
            .split("")
            .at(-1)}`;
          const linePaleArray = Object.keys(line.d);
          const linePaleMan = linePaleArray.find((item) => item === paleMan);
          const linePaleWoman = linePaleArray.find(
            (item) => item === paleWoman
          );

          setLineForPaleMan(linePaleMan);
          setLineForPaleWoman(linePaleWoman);
          setManDog(macho);
          setWomanDog(woman);
          setPaleManDog(paleMan);
          setPaleWomanDog(paleWoman);
          // Actualizar ganadores si existen
          if (runReq?.winnerThisRun?.winners) {
            setWinner({
              first: runReq.winnerThisRun.winners.first,
              second: runReq.winnerThisRun.winners.second,
              lineFirst: runReq.winnerThisRun.lineFirst.toFixed(1),
              linePale: runReq.winnerThisRun.linePale,
              third: runReq.winnerThisRun.winners.third,
              lineTrip: runReq.winnerThisRun.lineTrip.toFixed(1),
              run: runReq.run,
            });
          } else {
            setWinner(() => {
              return { first: "", second: "", lineFirst: 0, linePale: 0 };
            });
          }

          setLineUp(true);
        }
      } else {
        setLineUp(false);
      }
    };

    socket.on("run", handleRun);

    // Cleanup al desmontar el componente
    return () => {
      socket.off("run", handleRun);
    };
  }, [history, run, lineUp, lineForPaleMan, lineForPaleWoman]);

  /* useEffect(() => {
        // socket.on('active', (data)=>{
            socket.emit('active-res',{point:localStorage.getItem('point')}) 
        // })
        socket.on('run', (runReq) => {
            SetRun(()=>runReq)
            if (run) if (run.run) if (run.run.code) SetCode(run.run.code.toString().padStart(4, '0'))
            if (run && run.history) setHistory(value => run.history != value? run.history: value)
            if(run && run.run && run.run.line) SetLines(()=> run.line)
            line = run? run.run? run.run.line:undefined:undefined
            if (line) {
                if(!lineUp){
                // Asignacion de stilo para macho y hembra
                const lineArray = [{ label: 'p1', value: line.d.p1 }, { label: 'p2', value: line.d.p2 }, { label: 'p3', value: line.d.p3 }, { label: 'p4', value: line.d.p4 }, { label: 'p5', value: line.d.p5 }, { label: 'p6', value: line.d.p6 }, { label: 'p7', value: line.d.p7 }, { label: 'p8', value: line.d.p8 }].sort((a, b) => a.value - b.value)
                const macho = lineArray[0].label;
                const woman = lineArray[7].label;
                const paleMan = `${lineArray[1].label}${lineArray[0].label.split('').at(-1)}`;
                const paleWoman = `${lineArray[7].label}${lineArray[6].label.split('').at(-1)}`;
                const linePaleArray = Object.keys(line)
                const linePaleMan = linePaleArray.filter(item => item === paleMan)[0];
                const linePaleWoman = linePaleArray.filter(item => item === paleWoman)[0];
                setLineForPaleMan(pale => pale !== linePaleMan ? linePaleMan : pale)
                setLineForPaleWoman(pale => pale !== linePaleWoman ? linePaleWoman : pale)
                setManDog(macho)
                setWomanDog(woman)
                setPaleManDog(paleMan)
                setPaleWomanDog(paleWoman)


                // fin
                // Winner
                if (run && run.winnerThisRun && run.winnerThisRun.winners) {
                    setWinner(() => {
                        return {
                            first: run.winnerThisRun.winners.first,
                            second: run.winnerThisRun.winners.second,
                            lineFirst: run.winnerThisRun.lineFirst,
                            linePale: run.winnerThisRun.linePale
                        }
                    })
                } else {
                    // setWinner(() => {return { first: '', second: '', lineFirst: 0, linePale: 0 }})
                }
                SetLines(line)
                setLineUp(true)
            }
            }else{
                setLineUp(false)  
            }
        
        })
   },[history, line, code, winner, lineUp, lineForPaleMan, lineForPaleWoman])
*/
  //TODO:: MEDIR EL TIEMPO PARA NO HACER EL RELOAD TODAS LAS CARRERAS
  useEffect(() => {
    const handleScreen = (data) => {
      if (number.screen === 3) {
        if (result) setResult(false);
        if (localStorage.getItem("reload") === "false") {
          localStorage.setItem("reload", "true");
          //  window.location.reload();
        }
      } else {
        if (!result) setResult(true);
        if (localStorage.getItem("reload") === "true")
          localStorage.setItem("reload", "false");
      }

      if (data && data.jpg && jpg !== data.jpg.value) {
        setJpg(data.jpg.value || "");
        setLastWinerJpGral(data.jpg);
        if (!jpl) {
          socket.emit("jpl", "");
          socket.on("jpl-from-server", (jp) => setJpl(jp));
        }
        if (!jpc) {
          socket.emit("jpc", "");
          socket.on("jpc-from-server", (jp) => setJpc(jp));
        }
      }

      if (data.time) {
        setTime(data.time);
      }
    };

    socket.on("screen", handleScreen);

    return () => {
      socket.off("screen", handleScreen);
    };
  }, [result, time, jpc, jpl, jpg, lastWinerJpGral, number]);

  /**Function para apostar Jugador */

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setTrip(false);
  };

  const doBetPBylayer = async (type) => {
    const bodyBet = {
      dog: {
        firt: betThisPlayer.dog.toString().split("")[0],
        second:
          betThisPlayer.dog > 8
            ? betThisPlayer.dog.toString().split("")[1]
            : "",
        third:
          betThisPlayer.dog > 65
            ? betThisPlayer.dog.toString().split("")[2]
            : "",
      },
      amount: betted > 0 ? betted : 5,
      type: type,
      run: run,
    };
    const res = await fetch(`${url}/ticket`, {
      method: "post",
      headers: headers,
      body: JSON.stringify([bodyBet]),
    });
    const data = await res.json();
    if (data.toString().includes("reada")) {
      setClassMsg("green");
      setBetThisPlayer({ dog: "", pay: 0, line: "", run: "" });
    } else {
      setClassMsg("red");
    }
    setMsg(data);
    setTimeout(() => {
      setMsg("");
      setClassMsg("white");
      setShow(false);
    }, 1200);
  };
  const showModalForBet = (optionDog, lineThis) => {
    if (localStorage.getItem("level") === "5") {
      setBetThisPlayer({ dog: optionDog, line: lineThis });
      setBetted(25);
      handleShow();
    }
  };

  /**Agregar a monto de apuesta */
  const AddBetAmount = (value) => {
    if (betted + value <= 1000) {
      setBetted((betted) => parseFloat(betted) + parseFloat(value));
    } else {
      alert("Apuesta Maxima Superada");
    }
  };
  /**Agregar a numero para tripleta */
  const AddBetAmountNum = (value) => {
    if (betThisPlayer.dog < 65) {
      setBetThisPlayer((actual) => {
        return {
          ...actual,
          pay:
            actual.pay > 0
              ? actual.pay * lines.d[`p${value}`]
              : lines.d[`p${value}`],
          dog: parseInt(actual.dog.toString() + value.toString()),
        };
      });
    } else {
      alert("Opcion no valida");
    }
  };
  const handleChangeTripOption = () => {
    if (tripOption.includes("COLOCA")) {
      setTripOption("EXACTA");
    } else {
      setTripOption("COLOCADA");
    }
  };

  const cellWidth = dimensions.width * 0.3 * 0.2;
  const cellHeight = dimensions.height * 0.07;
  const headerHeight = dimensions.height * 0.3 * 0.245;

  if (number.screen === 3 || number.screen === 1) {
    if (dimenssion.width < dimenssion.height) {
      return (
        <div className="fs-1 text-white">
          Favor colocar el dispositivo de forma Horizontal
        </div>
      );
    } else {
      return (
        <Stage
          width={dimensions.width}
          height={dimensions.height}
          ref={stageRef}
        >
          {/**cabecera */}
          <Layer visible={ready}>
            <Group x={0} y={0} width={widthCanvas} height={heightCanvas * 0.1}>
              <Rect
                width={widthCanvas}
                height={heightCanvas * 0.1}
                fill="transparent"
              />
              {/* Left section */}
              <Group
                x={0}
                y={0}
                width={widthCanvas * 0.15}
                height={heightCanvas * 0.1}
              >
                <Text
                  text="HISTORIAL"
                  fill="white"
                  align="center"
                  verticalAlign="botton"
                  fontFamily="Arial Dark"
                  fontSize={sizeRef / 30}
                  x={0}
                  y={heightCanvas * 0.017}
                  width={widthCanvas * 0.1}
                  height={heightCanvas * 0.05}
                  stroke={"white"}
                  strokeWidth={1.2}
                />
                <KonvaImage
                  image={triang}
                  x={widthCanvas * 0.001}
                  y={heightCanvas * 0.05}
                  width={widthCanvas * 0.1}
                  height={heightCanvas * 0.05}
                  onClick={() =>
                    localStorage.getItem("level") === "5"
                      ? (window.location.href = "/admin-player")
                      : (window.location.href = "/bet")
                  }
                  onTouchStart={() =>
                    localStorage.getItem("level") === "5"
                      ? (window.location.href = "/admin-player")
                      : (window.location.href = "/bet")
                  }
                />
              </Group>
              <Group
                x={widthCanvas * 0.12}
                y={0}
                width={widthCanvas * 0.2}
                height={heightCanvas * 0.1}
              >
                <Text
                  text="PROXIMA CARRERA:"
                  fill="white"
                  fontSize={sizeRef / 40}
                  x={0}
                  y={0}
                  width={widthCanvas * 0.2}
                  height={heightCanvas * 0.05}
                  align="center"
                  verticalAlign="middle"
                  stroke={"white"}
                  strokeWidth={2}
                />
                <Text
                  text={code}
                  fill="white"
                  fontSize={sizeRef / 30}
                  align="center"
                  verticalAlign="middle"
                  x={0}
                  y={heightCanvas * 0.05}
                  width={widthCanvas * 0.2}
                  height={heightCanvas * 0.05}
                  stroke={"white"}
                  strokeWidth={1.2}
                />
              </Group>

              {/* Right section */}
              <Group
                x={widthCanvas * 0.3}
                y={0}
                width={widthCanvas * 0.7}
                height={heightCanvas * 0.1}
              >
                <Group
                  x={widthCanvas * 0.15}
                  y={0}
                  width={widthCanvas * 0.2}
                  height={heightCanvas * 0.05}
                  visible={localStorage.getItem("level") === "5"}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={widthCanvas * 0.2}
                    height={heightCanvas * 0.05}
                    fill="brown"
                    cornerRadius={10}
                  />
                  <Text
                    text={"Tripleta"}
                    x={0}
                    y={0}
                    width={widthCanvas * 0.2}
                    height={heightCanvas * 0.05}
                    fill="white"
                    cornerRadius={10}
                    align="center"
                    verticalAlign="middle"
                    fontSize={sizeRef / 25}
                    onTouchStart={() => setTrip(true)}
                  />
                </Group>
                <Group
                  x={widthCanvas - widthCanvas * 0.5}
                  y={0}
                  width={widthCanvas * 0.7}
                  height={heightCanvas * 0.05}
                >
                  <Text
                    text={""}
                    fill="red"
                    verticalAlign="top"
                    fontFamily="Arial Dark"
                    fontSize={sizeRef / 25}
                    x={0}
                    y={0}
                    width={widthCanvas * 0.3}
                    height={heightCanvas * 0.05}
                  />
                  <Text
                    text={""}
                    fill="white"
                    verticalAlign="top"
                    fontFamily="Arial Dark"
                    fontSize={sizeRef / 25}
                    x={widthCanvas * 0.045}
                    y={0}
                    width={widthCanvas * 0.3}
                    height={heightCanvas * 0.05}
                  />
                  <KonvaImage
                    image={imgLogo}
                    x={0}
                    y={0}
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.07}
                    visible={true}
                  />
                </Group>
                <Group
                  x={0}
                  y={heightCanvas * 0.06}
                  width={widthCanvas * 0.7}
                  height={heightCanvas * 0.05}
                >
                  <Group
                    x={0}
                    y={0}
                    width={widthCanvas * 0.05}
                    height={heightCanvas * 0.03}
                  >
                    <Rect
                      x={0}
                      y={0}
                      fill="blue"
                      cornerRadius={10}
                      stroke="grey"
                      width={widthCanvas * 0.05}
                      height={heightCanvas * 0.03}
                    />
                    <Text
                      text={`${time} s`}
                      fill="white"
                      fontSize={sizeRef / 60}
                      align="center"
                      verticalAlign="middle"
                      width={widthCanvas * 0.05}
                      height={heightCanvas * 0.03}
                      stroke={"white"}
                      strokeWidth={1.2}
                    />
                  </Group>
                  <Group
                    x={widthCanvas * 0.06}
                    y={heightCanvas * 0.01}
                    width={widthCanvas * 0.65}
                  >
                    <Rect
                      x={0}
                      y={0}
                      height={heightCanvas * 0.02}
                      fill="gray"
                      cornerRadius={5}
                      stroke="yellow"
                      width={widthCanvas * 0.62}
                    />
                    <Rect
                      x={widthCanvas * 0.001}
                      y={heightCanvas * 0.003}
                      width={(time / 240) * widthCanvas * 0.62}
                      height={heightCanvas * 0.013}
                      fill={rgb[parseInt(24 - time / 10)]}
                      cornerRadius={5}
                    />
                  </Group>
                </Group>
              </Group>
            </Group>
          </Layer>
          {/**Historial */}
          <Layer visible={ready}>
            <Group
              x={0}
              y={heightCanvas * 0.1}
              width={widthCanvas * 0.3}
              height={heightCanvas * 0.6}
            >
              <Rect
                x={0}
                y={0}
                width={widthCanvas * 0.25}
                height={heightCanvas * 0.635}
                fill={"#e0e0e0"}
                cornerRadius={10}
              />
              <Group x={0} y={0} width={cellWidth} height={cellHeight}>
                <Group x={0} y={0} width={1.5 * cellWidth} height={cellHeight}>
                  <Rect
                    x={0}
                    y={0}
                    width={1.7 * cellWidth}
                    height={cellHeight}
                    fill="darkblue"
                  />
                  <Text
                    x={0}
                    y={0}
                    text="Carreras"
                    fontSize={sizeRef / 40}
                    fill="white"
                    width={1.7 * cellWidth}
                    height={cellHeight}
                    align="center"
                    verticalAlign="middle"
                    stroke={"white"}
                    strokeWidth={1.2}
                  />
                </Group>
                <Group
                  x={1.7 * cellWidth}
                  y={0}
                  width={cellWidth}
                  height={cellHeight}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={cellWidth}
                    height={cellHeight}
                    fill="darkblue"
                  />
                  <Text
                    x={0}
                    y={0}
                    text="1ER"
                    fontSize={sizeRef / 40}
                    fill="white"
                    width={cellWidth}
                    height={cellHeight}
                    align="center"
                    verticalAlign="middle"
                    stroke={"white"}
                    strokeWidth={1.2}
                  />
                </Group>
                <Group
                  x={2.5 * cellWidth}
                  y={0}
                  width={cellWidth}
                  height={cellHeight}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={cellWidth}
                    height={cellHeight}
                    fill="darkblue"
                  />
                  <Text
                    x={0}
                    y={0}
                    text="2DO"
                    fontSize={sizeRef / 40}
                    fill="white"
                    align="center"
                    verticalAlign="middle"
                    width={cellWidth}
                    height={cellHeight}
                    stroke={"white"}
                    strokeWidth={1.2}
                  />
                </Group>
                <Group
                  x={3.5 * cellWidth}
                  y={0}
                  width={cellWidth}
                  height={cellHeight}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={cellHeight}
                    height={cellHeight}
                    fill="darkblue"
                  />
                  <Text
                    x={0}
                    y={0}
                    text="3ER"
                    fontSize={sizeRef / 40}
                    fill="white"
                    align="center"
                    verticalAlign="middle"
                    width={cellHeight}
                    height={cellHeight}
                    stroke={"white"}
                    strokeWidth={1.2}
                  />
                </Group>
              </Group>
              {history &&
                history.map((item, rowIndex) => (
                  <Group
                    key={rowIndex}
                    y={headerHeight + rowIndex * cellHeight}
                    width={widthCanvas * 0.3}
                  >
                    <Rect
                      x={0}
                      y={0}
                      width={1.7 * cellWidth}
                      height={cellHeight * 0.9}
                      fill="white"
                      stroke="grey"
                      cornerRadius={10}
                      padding={sizeRef * 0.1}
                    />
                    {item?.factor > 1 && (
                      <Group
                        x={0}
                        y={0}
                        width={0.1 * cellWidth}
                        height={cellHeight}
                      >
                        <Rect
                          x={0.03 * cellWidth}
                          y={0.05 * cellHeight}
                          width={0.3 * cellWidth}
                          height={0.8 * cellHeight}
                          fill="red"
                          stroke="red"
                          padding={sizeRef * 0.01}
                          cornerRadius={5}
                        />
                        <Text
                          x={0.03 * cellWidth}
                          y={0.1 * cellHeight}
                          width={0.3 * cellWidth}
                          height={0.4 * cellHeight}
                          text={"BONO"}
                          fontFamily="Courier New"
                          fontSize={sizeRef / 80}
                          fill="green"
                          verticalAlign="botton"
                          align="center"
                          stroke={"greenyellow"}
                          strokeWidth={1.2}
                        />
                        <Text
                          x={0.03 * cellWidth}
                          y={0.4 * cellHeight}
                          width={0.3 * cellWidth}
                          height={0.4 * cellHeight}
                          text={item?.factor === 3 ? "X3" : "X2"}
                          fontFamily="Arial Dark"
                          fontSize={sizeRef / 50}
                          fill="white"
                          verticalAlign="botton"
                          align="center"
                          stroke={"white"}
                          strokeWidth={1.2}
                        />
                      </Group>
                    )}
                    <Text
                      x={0}
                      y={0}
                      text={item.code.toString().padStart(6, "0")}
                      fontFamily="Arial Dark"
                      fontSize={sizeRef / 30}
                      fill="black"
                      verticalAlign="middle"
                      align="center"
                      width={1.7 * cellWidth}
                      height={cellHeight * 0.9}
                      stroke={"black"}
                      strokeWidth={1.2}
                    />
                    <Rect
                      x={2 * cellWidth}
                      y={0}
                      width={cellHeight * 0.9}
                      height={cellHeight * 0.9}
                      fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                      fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                      fillLinearGradientColorStops={
                        dogs[item.winner.fisrt - 1]?.bg
                      }
                      stroke="grey"
                      cornerRadius={10}
                      padding={sizeRef * 0.01}
                    />
                    <Text
                      x={2 * cellWidth}
                      y={0}
                      text={item.winner.fisrt}
                      fontFamily="Arial Dark"
                      fontSize={sizeRef / 30}
                      fill={dogs[item.winner.fisrt - 1]?.color}
                      verticalAlign="middle"
                      align="center"
                      width={cellHeight * 0.9}
                      height={cellHeight * 0.9}
                      stroke={dogs[item.winner.fisrt - 1]?.color}
                      strokeWidth={2}
                    />
                    <Rect
                      x={cellWidth * 2.7}
                      y={0}
                      width={cellHeight * 0.9}
                      height={cellHeight * 0.9}
                      fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                      fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                      fillLinearGradientColorStops={
                        dogs[item.winner.second - 1]?.bg
                      }
                      stroke="grey"
                      cornerRadius={10}
                      padding={sizeRef * 0.01}
                    />
                    <Text
                      x={cellWidth * 2.7}
                      y={0}
                      text={item.winner.second}
                      fontFamily="Arial Dark"
                      fontSize={sizeRef / 30}
                      fill={dogs[item.winner.second - 1]?.color}
                      verticalAlign="middle"
                      align="center"
                      width={cellHeight * 0.9}
                      height={cellHeight * 0.9}
                      stroke={dogs[item.winner.second - 1]?.color}
                      strokeWidth={2}
                    />
                    <Rect
                      x={cellWidth * 3.5}
                      y={0}
                      width={cellHeight * 0.9}
                      height={cellHeight * 0.9}
                      fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                      fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                      fillLinearGradientColorStops={
                        dogs[item.winner.third - 1]?.bg
                      }
                      stroke="grey"
                      cornerRadius={10}
                      padding={sizeRef * 0.01}
                    />
                    <Text
                      x={cellWidth * 3.5}
                      y={0}
                      text={item.winner.third}
                      fontFamily="Arial Dark"
                      fontSize={sizeRef / 30}
                      fill={dogs[item.winner.third - 1]?.color}
                      verticalAlign="middle"
                      align="center"
                      width={cellHeight * 0.9}
                      height={cellHeight * 0.9}
                      stroke={dogs[item.winner.third - 1]?.color}
                      strokeWidth={2}
                    />
                  </Group>
                ))}
            </Group>

            <Group
              x={widthCanvas * 0.3}
              y={heightCanvas * 0.1}
              width={widthCanvas * 0.7}
              height={heightCanvas * 0.8}
            >
              <Rect
                x={0}
                y={0}
                width={widthCanvas * 0.689}
                height={heightCanvas * 0.635}
                fill={"#e0e0e0"}
                cornerRadius={10}
              />
              {data.map((row, rowIndex) =>
                row.map((cell, colIndex) => {
                  const withCellLine = (widthCanvas * 0.6915) / 7;
                  const heightCellLine = (heightCanvas * 0.64) / 7;
                  return (
                    <React.Fragment key={`${rowIndex}-${colIndex}`}>
                      <Rect
                        x={colIndex * withCellLine}
                        y={rowIndex * heightCellLine}
                        width={withCellLine * 0.95}
                        height={heightCellLine * 0.95}
                        fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                        fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                        fillLinearGradientColorStops={
                          rowIndex === 0
                            ? colIndex === 0
                              ? [0, "#000"]
                              : dogs[colIndex - 1].bg
                            : colIndex === 0
                            ? dogs[rowIndex - 1].bg
                            : cell === manDog
                            ? [0, "skyblue"]
                            : cell.toString().includes("p")
                            ? parseInt(cell.toString().replace("p", "")) <= 6
                              ? womanDog === cell
                                ? [0, "pink"]
                                : [0, "#fff"]
                              : [0, "#fff"]
                            : [0, "#fff"]
                        }
                        stroke={
                          rowIndex !== 0 && rowIndex === colIndex
                            ? `${dogs[rowIndex - 1].stroke}`
                            : "gray"
                        }
                        strokeWidth={
                          rowIndex !== 0 && rowIndex === colIndex ? 2 : 1
                        }
                        cornerRadius={10}
                        padding={10}
                      />
                      <Text
                        x={colIndex * withCellLine}
                        y={rowIndex * heightCellLine}
                        width={withCellLine * 0.9}
                        height={heightCellLine * 0.9}
                        fontFamily="Bahnschrift"
                        align={
                          rowIndex === 0
                            ? colIndex === 0
                              ? "center"
                              : "center"
                            : colIndex === 0
                            ? "center"
                            : "center"
                        }
                        verticalAlign="middle"
                        text={
                          cell.toString().includes("p")
                            ? parseInt(cell.toString().replace("p", "")) <= 6
                              ? lines
                                ? parseFloat(
                                    lines.d[`${cell.toString()}`]
                                  ).toFixed(1)
                                : "--"
                              : lines
                              ? parseFloat(lines[`${cell.toString()}`]).toFixed(
                                  1
                                )
                              : "--"
                            : cell
                        }
                        fill={
                          rowIndex === 0
                            ? colIndex === 0
                              ? "white"
                              : dogs[colIndex - 1].color
                            : colIndex === 0
                            ? dogs[rowIndex - 1].color
                            : cell === manPaleDog
                            ? "blue"
                            : cell === womanPaleDog
                            ? "red"
                            : "black"
                        }
                        stroke={
                          rowIndex === 0
                            ? colIndex === 0
                              ? "white"
                              : dogs[colIndex - 1].color
                            : colIndex === 0
                            ? dogs[rowIndex - 1].color
                            : cell === manPaleDog
                            ? "blue"
                            : cell === womanPaleDog
                            ? "red"
                            : "black"
                        }
                        fontSize={
                          rowIndex === 0 && colIndex === 0
                            ? sizeRef / 30
                            : rowIndex === 0 && colIndex !== 0
                            ? sizeRef / 20
                            : rowIndex !== 0 && colIndex === 0
                            ? sizeRef / 20
                            : sizeRef / 30
                        }
                        strokeWidth={
                          rowIndex === 0 && colIndex !== 0
                            ? 1.2
                            : rowIndex !== 0 && colIndex === 0
                            ? 1.5
                            : 1
                        }
                        onTouchStart={() =>
                          cell.includes("p")
                            ? showModalForBet(
                                parseInt(cell.toString().replace("p", "")),
                                parseInt(cell.toString().replace("p", "")) <= 6
                                  ? parseFloat(
                                      lines.d[`${cell.toString()}`]
                                    ).toFixed(1)
                                  : parseFloat(
                                      lines[`${cell.toString()}`]
                                    ).toFixed(1)
                              )
                            : ""
                        }
                      />
                    </React.Fragment>
                  );
                })
              )}
            </Group>
          </Layer>
          {/**jackpot*/}
          <Layer visible={ready}>
            <Group
              x={0}
              y={heightCanvas * 0.75}
              width={widthCanvas}
              height={heightCanvas * 0.15}
            >
              <Group
                x={0}
                y={0}
                width={widthCanvas * 0.5}
                height={heightCanvas * 0.15}
              >
                <Text
                  text="Últimos JackPots Pagados"
                  width={widthCanvas * 0.5}
                  height={heightCanvas * 0.05}
                  fill="white"
                  fontSize={sizeRef / 30}
                  align="center"
                  stroke="white"
                  verticalAlign="middle"
                  strokeWidth={1.2}
                  cornerRadius={10}
                />
                <Group
                  x={0}
                  y={heightCanvas * 0.05}
                  width={(widthCanvas * 0.5) / 3}
                  height={heightCanvas * 0.15}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.5) / 3}
                    height={heightCanvas * 0.15}
                    fill="white"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.5) / 3}
                    height={heightCanvas * 0.05}
                    fill="orange"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Text
                    text="Jackpot Local"
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.05}
                    fill="black"
                    fontSize={sizeRef / 40}
                    align="center"
                    padding={sizeRef * 0.01}
                    verticalAlign="middle"
                    stroke={"black"}
                    strokeWidth={1}
                  />
                  <Text
                    text={`Ticket: ${
                      jpl ? (jpl.ticket === 0 ? "----" : jpl.ticket) : "----"
                    }`}
                    fill="black"
                    fontSize={sizeRef / 40}
                    align="center"
                    padding={sizeRef * 0.01}
                    verticalAlign="middle"
                    y={heightCanvas * 0.05}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.1}
                  />
                </Group>
                <Group
                  x={(widthCanvas * 0.51) / 3}
                  y={heightCanvas * 0.05}
                  width={(widthCanvas * 0.5) / 3}
                  height={heightCanvas * 0.15}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.5) / 3}
                    height={heightCanvas * 0.15}
                    fill="white"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.5) / 3}
                    height={heightCanvas * 0.05}
                    fill="orange"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Text
                    text="Jackpot Consorcio"
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.05}
                    fill="black"
                    fontSize={sizeRef / 40}
                    align="center"
                    verticalAlign="middle"
                    stroke={"black"}
                    strokeWidth={1}
                  />
                  <Text
                    text={`Ticket: ${
                      jpc ? (jpc.ticket === 0 ? "----" : jpc.ticket) : "----"
                    }`}
                    x={0}
                    y={heightCanvas * 0.05}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.04}
                    fill="black"
                    fontSize={sizeRef / 40}
                    align="center"
                  />
                  <Text
                    text={`${
                      jpc && jpc.point
                        ? jpc.point?.replace("SPORTS", "").replace("SPORT", "")
                        : "------"
                    }`}
                    x={0}
                    y={heightCanvas * 0.09}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.03}
                    fill="black"
                    fontSize={sizeRef / 40}
                    align="center"
                  />
                  <Text
                    text={`${jpc && jpc.point ? jpc?.address?.city : "------"}`}
                    x={0}
                    y={heightCanvas * 0.12}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.03}
                    fill="black"
                    fontSize={sizeRef / 50}
                    align="center"
                  />
                </Group>
                <Group
                  x={(2 * widthCanvas * 0.51) / 3}
                  y={heightCanvas * 0.05}
                  width={(widthCanvas * 0.5) / 3}
                  height={heightCanvas * 0.15}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.5) / 3}
                    height={heightCanvas * 0.15}
                    fill="white"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.5) / 3}
                    height={heightCanvas * 0.05}
                    fill="orange"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Text
                    text="Jackpot General"
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.05}
                    fill="black"
                    fontSize={sizeRef / 40}
                    align="center"
                    verticalAlign="middle"
                    stroke={"black"}
                    strokeWidth={1}
                  />
                  <Text
                    text={`Ticket: ${
                      lastWinerJpGral && lastWinerJpGral?.ticket !== 0
                        ? lastWinerJpGral?.ticket
                        : "-----"
                    }`}
                    fill="black"
                    fontSize={sizeRef / 40}
                    y={heightCanvas * 0.05}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.04}
                    align="center"
                  />
                  <Text
                    text={` ${
                      lastWinerJpGral && lastWinerJpGral.point
                        ? lastWinerJpGral.point
                            ?.replace("SPORTS", "")
                            .replace("SPORT", "")
                        : "-----"
                    }`}
                    x={0}
                    y={heightCanvas * 0.09}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.03}
                    fill="black"
                    fontSize={sizeRef / 40}
                    align="center"
                    verticalAlign="middle"
                  />
                  <Text
                    text={` ${
                      lastWinerJpGral && lastWinerJpGral.address
                        ? lastWinerJpGral?.address?.city
                        : "-----"
                    }`}
                    x={0}
                    y={heightCanvas * 0.12}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.03}
                    fill="black"
                    fontSize={sizeRef / 50}
                    align="center"
                    verticalAlign="middle"
                  />
                </Group>
              </Group>

              <Group
                x={widthCanvas * 0.51}
                y={0}
                width={widthCanvas * 0.48}
                height={heightCanvas * 0.15}
              >
                <Text
                  text="JackPots Actuales"
                  width={widthCanvas * 0.5}
                  height={heightCanvas * 0.05}
                  fill="white"
                  fontSize={sizeRef / 30}
                  align="center"
                  verticalAlign="middle"
                  stroke="white"
                  strokeWidth={1.2}
                  cornerRadius={10}
                />
                <Group
                  x={0}
                  y={heightCanvas * 0.05}
                  width={(widthCanvas * 0.48) / 3}
                  height={heightCanvas * 0.15}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.15}
                    fill="white"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.05}
                    fill="blue"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Text
                    x={0}
                    y={0}
                    text="Jackpot Local"
                    width={(widthCanvas * 0.48) / 3}
                    height={headerHeight * 0.05}
                    fontSize={sizeRef / 40}
                    padding={sizeRef * 0.01}
                    fill="white"
                    align="center"
                    stroke={"white"}
                    strokeWidth={1.2}
                  />
                  <Text
                    x={0}
                    y={heightCanvas * 0.05}
                    width={(widthCanvas * 0.5) / 3}
                    height={heightCanvas * 0.1}
                    verticalAlign="middle"
                    text={
                      jpl
                        ? parseFloat(jpl.value).toLocaleString("en-EN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"
                    }
                    fill="black"
                    align="center"
                    fontSize={sizeRef / 30}
                    padding={sizeRef * 0.01}
                    stroke={"black"}
                    strokeWidth={1.2}
                  />
                </Group>
                <Group
                  x={(widthCanvas * 0.49) / 3}
                  y={heightCanvas * 0.05}
                  width={(widthCanvas * 0.5) / 3}
                  height={heightCanvas * 0.15}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.15}
                    fill="white"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.05}
                    fill="blue"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Text
                    x={0}
                    y={0}
                    text="Jackpot Consorcio"
                    width={(widthCanvas * 0.48) / 3}
                    height={headerHeight * 0.05}
                    fontSize={sizeRef / 40}
                    padding={sizeRef * 0.01}
                    fill="white"
                    align="center"
                    stroke={"white"}
                    strokeWidth={1.2}
                  />
                  <Text
                    x={0}
                    y={heightCanvas * 0.05}
                    width={(widthCanvas * 0.5) / 3}
                    height={heightCanvas * 0.1}
                    verticalAlign="middle"
                    text={
                      jpc
                        ? parseFloat(jpc.value).toLocaleString("en-EN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"
                    }
                    fill="black"
                    align="center"
                    fontSize={sizeRef / 30}
                    padding={sizeRef * 0.01}
                    stroke={"black"}
                    strokeWidth={1.2}
                  />
                </Group>
                <Group
                  x={(2 * widthCanvas * 0.49) / 3}
                  y={heightCanvas * 0.05}
                  width={(widthCanvas * 0.5) / 3}
                  height={heightCanvas * 0.15}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.15}
                    fill="white"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Rect
                    x={0}
                    y={0}
                    width={(widthCanvas * 0.48) / 3}
                    height={heightCanvas * 0.05}
                    fill="blue"
                    stroke="grey"
                    strokeWidth={1}
                    cornerRadius={5}
                  />
                  <Text
                    x={0}
                    y={0}
                    text="Jackpot General"
                    width={(widthCanvas * 0.48) / 3}
                    height={headerHeight * 0.05}
                    fontSize={sizeRef / 40}
                    padding={sizeRef * 0.01}
                    fill="white"
                    align="center"
                    stroke={"white"}
                    strokeWidth={1.2}
                  />
                  <Text
                    x={0}
                    y={heightCanvas * 0.05}
                    width={(widthCanvas * 0.5) / 3}
                    height={heightCanvas * 0.1}
                    verticalAlign="middle"
                    text={
                      jpg
                        ? parseFloat(jpg).toLocaleString("en-EN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"
                    }
                    fill="black"
                    align="center"
                    fontSize={sizeRef / 30}
                    padding={sizeRef * 0.01}
                    stroke={"black"}
                    strokeWidth={1.2}
                  />
                </Group>
              </Group>
            </Group>

            {result === false && winner.first !== "" && (
              <Group
                x={0}
                y={heightCanvas * 0.01}
                width={widthCanvas * 0.99}
                height={heightCanvas * 0.99}
              >
                <Rect
                  x={0}
                  y={0}
                  width={widthCanvas * 0.99}
                  height={heightCanvas * 0.99}
                  fill="#000"
                  stroke={"red"}
                  strokeWidth={5}
                  cornerRadius={10}
                />
                <Text
                  text="GANADORES"
                  x={0}
                  y={heightCanvas * 0.1}
                  fontSize={sizeRef / 10}
                  fill="orange"
                  align="center"
                  width={widthCanvas}
                  height={heightCanvas * 0.2}
                  strokeWidth={3}
                />
                {/* Ejemplo de cómo dibujar un rectángulo y texto */}
                <Group
                  x={0}
                  y={heightCanvas * 0.25}
                  width={widthCanvas}
                  height={heightCanvas * 0.75}
                >
                  <Rect
                    x={widthCanvas * 0.01}
                    y={0}
                    width={widthCanvas * 0.35}
                    height={heightCanvas * 0.1}
                    fill="green"
                    cornerRadius={10}
                  />
                  <Text
                    text="GANADOR"
                    x={widthCanvas * 0.01}
                    y={0}
                    fontSize={sizeRef / 25}
                    fill="white"
                    align="center"
                    verticalAlign="middle"
                    width={widthCanvas * 0.35}
                    height={heightCanvas * 0.1}
                    stroke={"white"}
                    strokeWidth={1.2}
                  />
                  <Rect
                    x={widthCanvas * 0.43}
                    y={0}
                    fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                    fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                    fillLinearGradientColorStops={dogs[winner.first - 1]?.bg}
                    width={widthCanvas * 0.35}
                    height={heightCanvas * 0.1}
                    cornerRadius={10}
                  />
                  <Text
                    text={winner.first || "---"}
                    x={widthCanvas * 0.43}
                    y={heightCanvas * 0.01}
                    fontSize={sizeRef / 25}
                    fill={dogs[winner.first - 1]?.color}
                    width={widthCanvas * 0.35}
                    height={heightCanvas * 0.1}
                    align="center"
                    verticalAlign="middle"
                    stroke={dogs[winner.first - 1].color}
                    strokeWidth={1.2}
                  />
                  <Rect
                    x={widthCanvas * 0.83}
                    y={0}
                    fill="orange"
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.1}
                    cornerRadius={10}
                  />
                  <Text
                    text={winner.lineFirst}
                    x={widthCanvas * 0.83}
                    y={heightCanvas * 0.01}
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.1}
                    fontSize={sizeRef / 25}
                    align="center"
                    verticalAlign="middle"
                    fill="black"
                    stroke={"black"}
                    strokeWidth={1.2}
                  />
                </Group>
                <Group
                  x={0}
                  y={heightCanvas * 0.45}
                  width={widthCanvas}
                  height={heightCanvas * 0.75}
                >
                  <Rect
                    x={widthCanvas * 0.01}
                    y={0}
                    width={widthCanvas * 0.35}
                    height={heightCanvas * 0.1}
                    fill="green"
                    cornerRadius={10}
                  />
                  <Text
                    text="PALE"
                    x={widthCanvas * 0.01}
                    y={0}
                    fontSize={sizeRef / 25}
                    fill="white"
                    align="center"
                    verticalAlign="middle"
                    width={widthCanvas * 0.35}
                    height={heightCanvas * 0.1}
                    stroke={"white"}
                    strokeWidth={1.2}
                  />
                  <Rect
                    x={widthCanvas * 0.43}
                    y={0}
                    fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                    fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                    fillLinearGradientColorStops={dogs[winner.first - 1]?.bg}
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.1}
                    cornerRadius={10}
                  />
                  <Text
                    text={winner.first || "---"}
                    x={widthCanvas * 0.43}
                    y={heightCanvas * 0.01}
                    fontSize={sizeRef / 25}
                    fill={dogs[winner.first - 1]?.color}
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.1}
                    align="center"
                    verticalAlign="middle"
                    stroke={dogs[winner.first - 1]?.color}
                    strokeWidth={1.2}
                  />
                  <Rect
                    x={widthCanvas * 0.62}
                    y={0}
                    fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                    fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                    fillLinearGradientColorStops={dogs[winner.second - 1]?.bg}
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.1}
                    cornerRadius={10}
                  />
                  <Text
                    text={winner.second || "---"}
                    x={widthCanvas * 0.62}
                    y={heightCanvas * 0.01}
                    fontSize={sizeRef / 25}
                    fill={dogs[winner.second - 1]?.color}
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.1}
                    align="center"
                    verticalAlign="middle"
                    stroke={dogs[winner.second - 1]?.color}
                    strokeWidth={1.2}
                  />
                  <Rect
                    x={widthCanvas * 0.83}
                    y={0}
                    fill="orange"
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.1}
                    cornerRadius={10}
                  />
                  <Text
                    text={winner.linePale}
                    x={widthCanvas * 0.83}
                    y={0}
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.1}
                    fontSize={sizeRef / 25}
                    align="center"
                    verticalAlign="middle"
                    fill="black"
                    stroke={"black"}
                    strokeWidth={1.2}
                  />
                </Group>
                <Group
                  x={0}
                  y={heightCanvas * 0.65}
                  width={widthCanvas}
                  height={heightCanvas * 0.75}
                >
                  <Rect
                    x={widthCanvas * 0.01}
                    y={0}
                    width={widthCanvas * 0.35}
                    height={heightCanvas * 0.1}
                    fill="green"
                    cornerRadius={10}
                  />
                  <Text
                    text="TRIPLETA EXACTA"
                    x={widthCanvas * 0.01}
                    y={0}
                    fontSize={sizeRef / 25}
                    fill="white"
                    align="center"
                    verticalAlign="middle"
                    width={widthCanvas * 0.35}
                    height={heightCanvas * 0.1}
                    stroke={"white"}
                    strokeWidth={1.2}
                  />
                  <Rect
                    x={widthCanvas * 0.43}
                    y={0}
                    fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                    fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                    fillLinearGradientColorStops={dogs[winner.first - 1]?.bg}
                    width={widthCanvas * 0.1}
                    height={heightCanvas * 0.1}
                    cornerRadius={10}
                  />
                  <Text
                    text={winner.first || "---"}
                    x={widthCanvas * 0.43}
                    y={heightCanvas * 0.01}
                    fontSize={sizeRef / 25}
                    fill={dogs[winner.first - 1]?.color}
                    width={widthCanvas * 0.1}
                    height={heightCanvas * 0.1}
                    align="center"
                    verticalAlign="middle"
                    stroke={dogs[winner.first - 1].color}
                    strokeWidth={1.2}
                  />
                  <Rect
                    x={widthCanvas * 0.55}
                    y={0}
                    fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                    fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                    fillLinearGradientColorStops={dogs[winner.second - 1]?.bg}
                    width={widthCanvas * 0.1}
                    height={heightCanvas * 0.1}
                    cornerRadius={10}
                  />
                  <Text
                    text={winner.second || "---"}
                    x={widthCanvas * 0.55}
                    y={heightCanvas * 0.01}
                    fontSize={sizeRef / 25}
                    fill={dogs[winner.second - 1]?.color}
                    width={widthCanvas * 0.1}
                    height={heightCanvas * 0.1}
                    align="center"
                    verticalAlign="middle"
                    stroke={dogs[winner.second - 1]?.color}
                    strokeWidth={1.2}
                  />
                  <Rect
                    x={widthCanvas * 0.67}
                    y={0}
                    fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                    fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                    fillLinearGradientColorStops={dogs[winner.third - 1]?.bg}
                    width={widthCanvas * 0.1}
                    height={heightCanvas * 0.1}
                    cornerRadius={10}
                  />
                  <Text
                    text={winner.third || "---"}
                    x={widthCanvas * 0.67}
                    y={heightCanvas * 0.01}
                    fontSize={sizeRef / 25}
                    fill={dogs[winner.third - 1]?.color}
                    width={widthCanvas * 0.1}
                    height={heightCanvas * 0.1}
                    align="center"
                    verticalAlign="middle"
                    stroke={dogs[winner.third - 1]?.color}
                    strokeWidth={1.2}
                  />
                  <Rect
                    x={widthCanvas * 0.83}
                    y={0}
                    fill="orange"
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.1}
                    cornerRadius={10}
                  />
                  <Text
                    text={winner?.lineTrip}
                    x={widthCanvas * 0.83}
                    y={heightCanvas * 0.01}
                    width={widthCanvas * 0.15}
                    height={heightCanvas * 0.1}
                    fontSize={sizeRef / 25}
                    align="center"
                    verticalAlign="middle"
                    fill="black"
                    stroke={"black"}
                    strokeWidth={1.2}
                  />
                </Group>
              </Group>
            )}
            <Group
              x={0}
              y={heightCanvas - heightCanvas * 0.05}
              width={widthCanvas}
              height={heightCanvas * 0.05}
              visible={localStorage.getItem("level" !== "5")}
            >
              <KonvaImage
                image={wsImg}
                x={widthCanvas * 0.75}
                y={heightCanvas * 0.0}
                width={widthCanvas * 0.04}
                height={heightCanvas * 0.04}
              />
              <Text
                text="Soporte Técnico y Reclamaciones en Rep. Dom. (849) 863-1426"
                width={widthCanvas}
                height={heightCanvas * 0.05}
                fill="yellow"
                align="center"
                verticalAlign="middle"
                fontSize={sizeRef / 40}
              />
            </Group>
          </Layer>
          <Layer visible={show || trip}>
            {trip ? (
              <Group
                x={widthCanvas * 0.1}
                y={heightCanvas * 0.1}
                width={window.innerWidth * 0.8}
                height={window.innerHeight * 0.8}
              >
                <Rect
                  width={window.innerWidth * 0.8}
                  height={window.innerHeight * 0.8}
                  fill="white"
                  shadowBlur={10}
                />
                <Rect
                  x={widthCanvas * 0.75}
                  y={heightCanvas * 0.01}
                  width={window.innerWidth * 0.05}
                  height={window.innerHeight * 0.05}
                  fill="white"
                  stroke={"black"}
                  cornerRadius={5}
                  onTouchStart={handleClose}
                />
                <Text
                  text="X"
                  x={widthCanvas * 0.75}
                  y={heightCanvas * 0.01}
                  width={window.innerWidth * 0.05}
                  height={window.innerHeight * 0.05}
                  align="center"
                  verticalAlign="middle"
                  fontSize={sizeRef / 30}
                  onTouchStart={handleClose}
                />
                <Text
                  text="Nueva Jugada Tripleta"
                  fontSize={sizeRef / 40}
                  x={widthCanvas * 0.01}
                  y={heightCanvas * 0.05}
                  width={window.innerWidth * 0.8}
                />
                <Rect
                  x={widthCanvas * 0.4}
                  y={heightCanvas * 0.01}
                  width={window.innerWidth * 0.25}
                  height={window.innerHeight * 0.05}
                  fill={classMsg}
                  cornerRadius={10}
                />
                <Text
                  text={msg}
                  x={widthCanvas * 0.4}
                  y={heightCanvas * 0.01}
                  width={window.innerWidth * 0.25}
                  height={window.innerHeight * 0.05}
                  fontSize={sizeRef / 30}
                  fill={classMsg === "red" ? "yellow" : "white"}
                  align="center"
                  verticalAlign="middle"
                />
                <Group
                  x={widthCanvas * 0.55}
                  y={heightCanvas * 0.1}
                  width={window.innerWidth * 0.2}
                  height={window.innerHeight * 0.2}
                  onTouchStart={handleChangeTripOption}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={window.innerWidth * 0.15}
                    height={window.innerHeight * 0.04}
                    fill={tripOption.includes("COL") ? "blue" : "red"}
                    cornerRadius={10}
                  />
                  <Text
                    text={tripOption}
                    x={0}
                    y={0}
                    width={window.innerWidth * 0.15}
                    height={window.innerHeight * 0.04}
                    fill={tripOption.includes("COL") ? "white" : "yellow"}
                    align="center"
                    verticalAlign="middle"
                    fontSize={sizeRef / 40}
                  />
                </Group>
                <Group
                  x={widthCanvas * 0.1}
                  y={heightCanvas * 0.15}
                  width={window.innerWidth * 0.6}
                  height={window.innerHeight * 0.2}
                >
                  <Group
                    x={0}
                    y={0}
                    width={window.innerWidth * 0.6}
                    height={window.innerHeight * 0.2}
                  >
                    <Rect
                      x={0}
                      y={0}
                      width={window.innerWidth * 0.6}
                      height={window.innerHeight * 0.05}
                      fill="gray"
                    />
                    <Text
                      text="Jugadas"
                      x={0}
                      y={0}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text="Apostado"
                      x={window.innerWidth * 0.15}
                      y={0}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text="Paga"
                      x={window.innerWidth * 0.3}
                      y={0}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text="Ganancia"
                      x={window.innerWidth * 0.45}
                      y={0}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                  </Group>
                  <Group
                    x={0}
                    y={heightCanvas * 0.01}
                    width={window.innerWidth * 0.6}
                    height={window.innerHeight * 0.2}
                  >
                    <Text
                      text={
                        betThisPlayer.dog > 0
                          ? `${tripOption.includes("COL") ? "TC" : "TE"}-${
                              betThisPlayer.dog.toString().split("")[0]
                            }-${
                              betThisPlayer.dog.toString().split("")[1]
                                ? betThisPlayer.dog.toString().split("")[1]
                                : ""
                            }-${
                              betThisPlayer.dog.toString().split("")[2]
                                ? betThisPlayer.dog.toString().split("")[2]
                                : ""
                            }`
                          : ""
                      }
                      x={0}
                      y={window.innerHeight * 0.05}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text={`${betted}`}
                      x={window.innerWidth * 0.15}
                      y={window.innerHeight * 0.05}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text={`${
                        tripOption.includes("EXACTA")
                          ? betThisPlayer.pay.toFixed(1)
                          : (betThisPlayer.pay / 3).toFixed(1)
                      }`}
                      x={window.innerWidth * 0.3}
                      y={window.innerHeight * 0.05}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text={`${
                        tripOption.includes("EXACTA")
                          ? (betThisPlayer.pay * betted).toFixed(0)
                          : ((betThisPlayer.pay * betted) / 3).toFixed(0)
                      }`}
                      x={window.innerWidth * 0.45}
                      y={window.innerHeight * 0.05}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                  </Group>
                </Group>
                <Group
                  x={widthCanvas * 0.075}
                  y={heightCanvas * 0.27}
                  width={widthCanvas * 0.8}
                  height={heightCanvas * 0.075}
                >
                  {[1, 2, 3, 4, 5, 6].map((num, index) => (
                    <Rect
                      key={index}
                      x={widthCanvas * 0.05 + index * widthCanvas * 0.071}
                      width={widthCanvas * 0.06}
                      height={heightCanvas * 0.075}
                      fill="back"
                    />
                  ))}
                  {[1, 2, 3, 4, 5, 6].map((num, index) => (
                    <Text
                      key={index}
                      text={(lines?.d[`p${num}`]).toFixed(1)}
                      x={widthCanvas * 0.05 + index * widthCanvas * 0.071}
                      width={widthCanvas * 0.06}
                      height={heightCanvas * 0.075}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 25}
                      onTouchStart={() => AddBetAmountNum(num)}
                      fill={"yellow"}
                    />
                  ))}
                </Group>
                <Group
                  x={widthCanvas * 0.075}
                  y={heightCanvas * 0.35}
                  width={widthCanvas * 0.8}
                  height={heightCanvas * 0.2}
                >
                  {[1, 2, 3, 4, 5, 6].map((num, index) => (
                    <Rect
                      key={index}
                      x={widthCanvas * 0.05 + index * widthCanvas * 0.071}
                      width={widthCanvas * 0.06}
                      height={heightCanvas * 0.1}
                      fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                      fillLinearGradientEndPoint={{ x: 0, y: 100 }}
                      fillLinearGradientColorStops={dogs[index].bg}
                      onTouchStart={() => AddBetAmountNum(num)}
                      cornerRadius={5}
                      stroke={"blue"}
                    />
                  ))}
                  {[1, 2, 3, 4, 5, 6].map((num, index) => (
                    <Text
                      key={index}
                      text={num}
                      x={widthCanvas * 0.05 + index * widthCanvas * 0.071}
                      width={widthCanvas * 0.06}
                      height={heightCanvas * 0.1}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 25}
                      onTouchStart={() => AddBetAmountNum(num)}
                      fill={dogs[index].color}
                    />
                  ))}
                </Group>
                <Group
                  x={widthCanvas * 0.025}
                  y={heightCanvas * 0.5}
                  width={widthCanvas * 0.8}
                  height={heightCanvas * 0.2}
                >
                  {[5, 10, 25, 50, 100].map((amount, index) => (
                    <Rect
                      key={index}
                      x={widthCanvas * 0.11 + index * widthCanvas * 0.11}
                      width={widthCanvas * 0.1}
                      height={heightCanvas * 0.1}
                      fill="green"
                      onTouchStart={() => AddBetAmount(amount)}
                      cornerRadius={5}
                    />
                  ))}
                  {[5, 10, 25, 50, 100].map((amount, index) => (
                    <Text
                      key={index}
                      text={`$${amount}`}
                      x={widthCanvas * 0.11 + index * widthCanvas * 0.11}
                      width={widthCanvas * 0.1}
                      height={heightCanvas * 0.1}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 30}
                      onTouchStart={() => AddBetAmount(amount)}
                      fill="white"
                    />
                  ))}
                </Group>

                <Group
                  x={widthCanvas * 0.05}
                  y={heightCanvas * 0.65}
                  width={widthCanvas * 0.8}
                  height={heightCanvas * 0.125}
                >
                  <Rect
                    width={widthCanvas * 0.3}
                    height={heightCanvas * 0.125}
                    cornerRadius={5}
                    fill="orange"
                    onTouchStart={() =>
                      setBetThisPlayer({ dog: "", line: "", pay: 0 })
                    }
                  />
                  <Text
                    text="CANCELAR"
                    width={widthCanvas * 0.3}
                    height={heightCanvas * 0.125}
                    align="center"
                    verticalAlign="middle"
                    fontSize={sizeRef / 30}
                    fill="white"
                    onTouchStart={() => setBetThisPlayer({ dog: "", line: "" })}
                  />

                  <Rect
                    x={widthCanvas * 0.425}
                    y={0}
                    width={widthCanvas * 0.3}
                    height={heightCanvas * 0.125}
                    cornerRadius={5}
                    fill="blue"
                    onTouchStart={() => doBetPBylayer(tripOption)}
                  />
                  <Text
                    text="CREAR"
                    x={widthCanvas * 0.425}
                    width={widthCanvas * 0.3}
                    height={heightCanvas * 0.125}
                    align="center"
                    verticalAlign="middle"
                    fontSize={sizeRef / 30}
                    fill="white"
                    onTouchStart={() => doBetPBylayer(tripOption)}
                  />
                </Group>
              </Group>
            ) : (
              <Group
                x={widthCanvas * 0.1}
                y={heightCanvas * 0.1}
                width={window.innerWidth * 0.8}
                height={window.innerHeight * 0.8}
              >
                <Rect
                  width={window.innerWidth * 0.8}
                  height={window.innerHeight * 0.8}
                  fill="white"
                  shadowBlur={10}
                />
                <Rect
                  x={widthCanvas * 0.75}
                  y={heightCanvas * 0.01}
                  width={window.innerWidth * 0.05}
                  height={window.innerHeight * 0.05}
                  fill="white"
                  stroke={"black"}
                  cornerRadius={5}
                  onTouchStart={handleClose}
                />
                <Text
                  text="X"
                  x={widthCanvas * 0.75}
                  y={heightCanvas * 0.01}
                  width={window.innerWidth * 0.05}
                  height={window.innerHeight * 0.05}
                  align="center"
                  verticalAlign="middle"
                  fontSize={sizeRef / 30}
                  onTouchStart={handleClose}
                />
                <Text
                  text="Nueva Jugada"
                  fontSize={sizeRef / 40}
                  x={widthCanvas * 0.01}
                  y={heightCanvas * 0.05}
                  width={window.innerWidth * 0.8}
                />
                <Rect
                  x={widthCanvas * 0.4}
                  y={heightCanvas * 0.01}
                  width={window.innerWidth * 0.25}
                  height={window.innerHeight * 0.1}
                  fontSize={sizeRef / 40}
                  fill={classMsg}
                  cornerRadius={10}
                />
                <Text
                  text={msg}
                  x={widthCanvas * 0.4}
                  y={heightCanvas * 0.01}
                  width={window.innerWidth * 0.25}
                  height={window.innerHeight * 0.1}
                  fontSize={sizeRef / 30}
                  fill={classMsg === "red" ? "yellow" : "white"}
                  align="center"
                  verticalAlign="middle"
                />
                <Group
                  x={widthCanvas * 0.1}
                  y={heightCanvas * 0.15}
                  width={window.innerWidth * 0.6}
                  height={window.innerHeight * 0.2}
                >
                  <Group
                    x={0}
                    y={0}
                    width={window.innerWidth * 0.6}
                    height={window.innerHeight * 0.2}
                  >
                    <Rect
                      x={0}
                      y={0}
                      width={window.innerWidth * 0.6}
                      height={window.innerHeight * 0.05}
                      fill="gray"
                    />
                    <Text
                      text="Jugadas"
                      x={0}
                      y={0}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text="Apostado"
                      x={window.innerWidth * 0.15}
                      y={0}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text="Paga"
                      x={window.innerWidth * 0.3}
                      y={0}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text="Ganancia"
                      x={window.innerWidth * 0.45}
                      y={0}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                  </Group>
                  <Group
                    x={0}
                    y={heightCanvas * 0.01}
                    width={window.innerWidth * 0.6}
                    height={window.innerHeight * 0.2}
                  >
                    <Text
                      text={
                        betThisPlayer.dog > 8
                          ? `P-${betThisPlayer.dog.toString().split("")[0]}-${
                              betThisPlayer.dog.toString().split("")[1]
                            }`
                          : `G-${betThisPlayer.dog}`
                      }
                      x={0}
                      y={window.innerHeight * 0.05}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text={`${betted}`}
                      x={window.innerWidth * 0.15}
                      y={window.innerHeight * 0.05}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text={`${betThisPlayer.line}`}
                      x={window.innerWidth * 0.3}
                      y={window.innerHeight * 0.05}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                    <Text
                      text={`${betThisPlayer.line * betted}`}
                      x={window.innerWidth * 0.45}
                      y={window.innerHeight * 0.05}
                      width={window.innerWidth * 0.15}
                      height={window.innerHeight * 0.05}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 40}
                    />
                  </Group>
                </Group>

                <Text
                  text="Monto Apostar"
                  fontSize={sizeRef / 30}
                  x={widthCanvas * 0.01}
                  y={heightCanvas * 0.3}
                  width={window.innerWidth * 0.7}
                  height={window.innerHeight * 0.05}
                  align="center"
                  verticalAlign="middle"
                />

                <Group
                  x={widthCanvas * 0.125}
                  y={heightCanvas * 0.4}
                  width={widthCanvas * 0.8}
                  height={heightCanvas * 0.2}
                >
                  {[10,15, 25, 50, 100].map((amount, index) => (
                    <Rect
                      key={index}
                      x={index * widthCanvas * 0.11}
                      width={widthCanvas * 0.1}
                      height={heightCanvas * 0.15}
                      fill="green"
                      onTouchStart={() => AddBetAmount(amount)}
                      cornerRadius={5}
                    />
                  ))}
                  {[10, 15, 25, 50, 100].map((amount, index) => (
                    <Text
                      key={index}
                      text={`$${amount}`}
                      x={index * widthCanvas * 0.11}
                      width={widthCanvas * 0.1}
                      height={heightCanvas * 0.15}
                      align="center"
                      verticalAlign="middle"
                      fontSize={sizeRef / 30}
                      onTouchStart={() => AddBetAmount(amount)}
                      fill="white"
                    />
                  ))}
                </Group>

                <Group
                  x={widthCanvas * 0.05}
                  y={heightCanvas * 0.6}
                  width={widthCanvas * 0.8}
                  height={heightCanvas * 0.15}
                >
                  <Rect
                    width={widthCanvas * 0.3}
                    height={heightCanvas * 0.15}
                    cornerRadius={5}
                    fill="orange"
                    onTouchStart={() => doBetPBylayer("DIRECTO")}
                  />
                  <Text
                    text="CREAR DIRECTA"
                    width={widthCanvas * 0.3}
                    height={heightCanvas * 0.15}
                    onTouchStart={() => doBetPBylayer("DIRECTO")}
                    align="center"
                    verticalAlign="middle"
                    fontSize={sizeRef / 30}
                    fill="white"
                  />

                  <Rect
                    x={widthCanvas * 0.425}
                    y={0}
                    width={widthCanvas * 0.3}
                    height={heightCanvas * 0.15}
                    cornerRadius={5}
                    fill="blue"
                    onTouchStart={() =>
                      doBetPBylayer(
                        betThisPlayer.dog <= 6
                          ? "CON TODOS"
                          : "PATRA Y PA'LANTE"
                      )
                    }
                  />
                  <Text
                    text={
                      betThisPlayer.dog <= 6 ? "CON TODOS" : "PATRA Y PA'LANTE"
                    }
                    x={widthCanvas * 0.425}
                    width={widthCanvas * 0.3}
                    height={heightCanvas * 0.15}
                    align="center"
                    verticalAlign="middle"
                    fontSize={sizeRef / 30}
                    fill="white"
                    onTouchStart={() =>
                      doBetPBylayer(
                        betThisPlayer.dog <= 6
                          ? "CON TODOS"
                          : "PATRA Y PA'LANTE"
                      )
                    }
                  />
                </Group>
              </Group>
            )}
          </Layer>
        </Stage>
      );
    }
  } else {
    return <div className="error">Error</div>;
  }
}

export default Lines;

// import React, { useState, useEffect, useRef} from 'react'
// import { socket } from '../../io';
// import './linea_responsive.css';
// import triangulo from '../../assets/venta/triangulo.png';
// import logo from '../../assets/logo.png'
// import ws from '../../assets/venta/ws1.png'
// import { headers , url} from '../../utils/const';
// import { Stage, Layer, Rect, Text, Group,   Image as KonvaImage} from 'react-konva';
// const rgb = [
//     "#0000FF", "#0000FF", "#0000FF", "#0000FF", "#0000FF","#0000FF",
//     "#0000FF", "#0000FF", "#0000FF", "#0000FF","#0000FF","#0000FF",
//     "#0000FF", "#0000FF","#0000FF","#0000FF", "#0000FF", "#0000FF",
//     "#FF9900", "#FF9900", "#FF9900", "#FF6600",
//     "#FF3300", "#FF0000"
//   ]

// const dogs = [
//     {num:1, bg: [], color: 'white', stroke:'red'}, // style1
//     {num:2, bg: [], color: 'red' , stroke:'brown'},   // style2
//     {num:3, bg: [], color: 'black' , stroke:'black'}, // style3
//     {num:4, bg: [], color: 'white' , stroke:'blue'}, // style4
//     {num:5, bg: [], color: 'black' , stroke:'yellow'},  // style5
//     {num:6, bg: [], color: 'orange' , stroke:'green'},  // style6
//     {num:7, bg: [], color: 'yellow' , stroke:'blak'}, // style7
//     {num:8, bg: [], color: 'white' , stroke:'violet'},  // style8
//   ];
// const bgArray1 = ['rgb(255, 240, 240), rgb(255, 155, 155), rgb(255, 72, 72), rgb(255, 0, 0), rgb(248, 0, 0), rgb(240, 0, 0), rgb(236, 0, 0), rgb(232, 0, 0), rgb(242, 0, 0), rgb(255, 36, 36), rgb(249, 61, 61), rgb(217, 125, 125)'][0].replace(/, r/g,',,r').split(',,').map(z=>z)
// const bgArray2 =['#fff, #111, #fff, #111, #fff, #111, #fff, #111'][0].replace(/, #/g,',,#').split(',,').map(z=>z)
// const bgArray3 =['#fff, #e6e6e6, #fff, #fdfdfd, #fff, #e6e6e6'][0].replace(/, #/g,',,#').split(',,').map(z=>z)
// const bgArray4 =['#a8e3f1, #76f6f4, #56dff1, #25d4f7, #29acf8, #0f9ef6, #76c5f6'][0].replace(/, #/g,',,#').split(',,').map(z=>z)
// const bgArray5 =['rgb(255, 128, 0), rgb(251, 180, 109), rgb(252, 167, 81), rgb(255, 143, 32), rgb(255, 128, 0), rgb(255, 128, 0), rgb(255, 128, 0), rgb(253, 127, 0), rgb(255, 136, 17), rgb(255, 150, 45), rgb(255, 193, 130), rgb(252, 184, 116)'][0].replace(/, r/g,',,r').split(',,').map(z=>z)
// const bgArray6 =['#688a6c, #398242, #177422, #087c16, #00800f, #0d851b, #22862e, #408b48, #658e69'][0].replace(/, #/g,',,#').split(',,').map(z=>z)
// const bgArray7 =['#404040, #383838, #161616, #272727, #101010, #000000, #181818, #151515, #2b2a2a'][0].replace(/, #/g,',,#').split(',,').map(z=>z)
// const bgArray8 =['#ee82ee, #f578f5, #f161f1, #ec24ec, #ed07ed, #f020f0, #f144f1, #f357f3, #ee82ee, #ee82ee'][0].replace(/, #/g,',,#').split(',,').map(z=>z)
// bgArray1.forEach((item, index)=>{
//     dogs[0].bg.push(index/bgArray1.length)
//     dogs[0].bg.push(item)
// })
// bgArray2.forEach((item, index)=>{
//     dogs[1].bg.push(index/bgArray1.length)
//     dogs[1].bg.push(item)
// })
// bgArray3.forEach((item, index)=>{
//     dogs[2].bg.push(index/bgArray1.length)
//     dogs[2].bg.push(item)
// })
// bgArray4.forEach((item, index)=>{
//     dogs[3].bg.push(index/bgArray1.length)
//     dogs[3].bg.push(item)
// })
// bgArray5.forEach((item, index)=>{
//     dogs[4].bg.push(index/bgArray1.length)
//     dogs[4].bg.push(item)
// })
// bgArray6.forEach((item, index)=>{
//     dogs[5].bg.push(index/bgArray1.length)
//     dogs[5].bg.push(item)
// })
// bgArray7.forEach((item, index)=>{
//     dogs[6].bg.push(index/bgArray1.length)
//     dogs[6].bg.push(item)
// })
// bgArray8.forEach((item, index)=>{
//     dogs[7].bg.push(index/bgArray1.length)
//     dogs[7].bg.push(item)
// })

//   const data = [
//     ["GANADOR", 1, 2, 3, 4, 5, 6, 7, 8],
//     [1, "p1", "p12", "p13", "p14", "p15", "p16", "p17", "p18"],
//     [2, "p21", "p2", "p23", "p24", "p25", "p26", "p27", "p28"],
//     [3, "p31", "p32", "p3", "p34", "p35", "p36", "p37", "p38"],
//     [4, "p41", "p42", "p43", "p4", "p45", "p46", "p47", "p48"],
//     [5, "p51", "p52", "p53", "p54", "p5", "p56", "p57", "p58"],
//     [6, "p61", "p62", "p63", "p64", "p65", "p6", "p67", "p68"],
//     [7, "p71", "p72", "p73", "p74", "p75", "p76", "p7", "p78"],
//     [8, "p81", "p82", "p83", "p84", "p85", "p86", "p87", "p8"],
//   ];

// function Lines(number) {
//   const [ready, setReady] = useState(false)
//     const stageRef = useRef(null);
//     const [triang, setTriang] = useState();
//     const [sizeRef, setSizeRef] = useState(1024)
//     const [msg, setMsg] = useState('');
//     const [classMsg, setClassMsg] = useState('white');
//     const [betted, setBetted] = useState(10);
//     const [betThisPlayer, setBetThisPlayer] = useState({dog:'', line:'', pay:0})
//     const [show, setShow] = useState(false)
//     const [lineForPaleMan, setLineForPaleMan] = useState();
//     const [lineForPaleWoman, setLineForPaleWoman] = useState()
//     localStorage.setItem('reload', true)
//     const [result, setResult] = useState(false);
//     const [lastWinerJpGral, setLastWinerJpGral] = useState();
//     const [winner, setWinner] = useState({ first: '', second: '', lineFirst: 0, linePale: 0 })
//     const [time, setTime] = useState();
//     const [jpg, setJpg] = useState();
//     const [jpl, setJpl] = useState();
//     const [jpc, setJpc] = useState();
//     const [lines, SetLines] = useState();
//     const [run, SetRun] = useState();
//     const [code, SetCode] = useState();
//     const [history, setHistory] = useState();
//      const [manDog, setManDog] = useState()
//     const [womanDog, setWomanDog] = useState()
//     const [manPaleDog, setPaleManDog] = useState()
//     const [womanPaleDog, setPaleWomanDog] = useState()
//     const [lineUp, setLineUp] = useState(false);
//     const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
//     const [imgLogo, setImgLogo] = useState();
//     const [trip, setTrip] = useState(false)
//     const [widthCanvas, setWidthCanvas ] = useState(window.innerWidth*0.99)
//     const [heightCanvas, setHeightCanvas] = useState(window.innerHeight*0.99)
//     const [wsImg, setWsimg] = useState()
//     const [tripOption, setTripOption] = useState('COLOCADA')
//     const [dimenssion, setDimenssion] = useState({
//       width: window.innerWidth,
//       height: window.innerHeight,
//     });
//     useEffect(() => {
//       const handleResize = () => {
//         setDimenssion({ width: window.innerWidth, height: window.innerHeight });
//       };
//       window.addEventListener("resize", handleResize);
//       return () => {
//         window.removeEventListener("resize", handleResize);
//       };
//     }, [dimenssion]);
//     useEffect(()=>{
//       const img = new window.Image();
//       img.src = logo;
//       img.onload = ()=> setImgLogo(img)
//   },[])
//   useEffect(()=>{
//     const img = new window.Image();
//     img.src = ws;
//     img.onload = ()=> setWsimg(img)
// },[])
//     useEffect(()=>{
//         const img = new window.Image();
//         img.src = triangulo;
//         img.onload = ()=> setTriang(img)
//     },[])
//     useEffect(()=>{
//         const w = window.innerWidth;
//         const h = window.innerHeight;
//         setSizeRef(w<h? w:h)
//         },[sizeRef])
//     useEffect(() => {

//         const handleResize = () => {
//                   const w = window.innerWidth;
//         const h = window.innerHeight;
//         setSizeRef(w<h? w:h)
//         setWidthCanvas(window.innerWidth*0.99)
//         setHeightCanvas(window.innerHeight*0.99)
//         };
//      handleResize();
//         window.addEventListener('resize', handleResize);

//         return () => {
//           window.removeEventListener('resize', handleResize);
//         };
//       }, []);
//       useEffect(() => {
//         // Emitir 'active-res' al conectar
//         socket.emit('active-res', { point: localStorage.getItem('point') });

//         // Handler para el evento 'run'
//         const handleRun = (runReq) => {
//             SetRun(runReq);

//             // Actualizar el código si existe
//             if (runReq?.run?.code) {
//                 SetCode(runReq.run.code.toString().padStart(4, '0'));
//             }

//             // Actualizar historial si es diferente
//             if (runReq?.history && runReq.history !== history) {
//                 setHistory(runReq.history);
//             }

//             // Actualizar líneas si existen
//             if (runReq?.run?.line) {
//                 SetLines(runReq.run.line);
//                 const line = runReq.run.line;
//                 setReady(true)

//                 if (!lineUp) {
//                     // Asignación de estilo para macho y hembra
//                     const lineArray = [
//                         { label: 'p1', value: line.d.p1 },
//                         { label: 'p2', value: line.d.p2 },
//                         { label: 'p3', value: line.d.p3 },
//                         { label: 'p4', value: line.d.p4 },
//                         { label: 'p5', value: line.d.p5 },
//                         { label: 'p6', value: line.d.p6 },
//                         { label: 'p7', value: line.d.p7 },
//                         { label: 'p8', value: line.d.p8 }
//                     ].sort((a, b) => a.value - b.value);

//                     const macho = lineArray[0].label;
//                     const woman = lineArray[7].label;
//                     const paleMan = `${lineArray[1].label}${lineArray[0].label.split('').at(-1)}`;
//                     const paleWoman = `${lineArray[7].label}${lineArray[6].label.split('').at(-1)}`;
//                     const linePaleArray = Object.keys(line.d);
//                     const linePaleMan = linePaleArray.find(item => item === paleMan);
//                     const linePaleWoman = linePaleArray.find(item => item === paleWoman);

//                     setLineForPaleMan(linePaleMan);
//                     setLineForPaleWoman(linePaleWoman);
//                     setManDog(macho);
//                     setWomanDog(woman);
//                     setPaleManDog(paleMan);
//                     setPaleWomanDog(paleWoman);
//                     // Actualizar ganadores si existen
//                     if (runReq?.winnerThisRun?.winners) {
//                         setWinner({
//                             first: runReq.winnerThisRun.winners.first,
//                             second: runReq.winnerThisRun.winners.second,
//                             lineFirst: runReq.winnerThisRun.lineFirst.toFixed(1),
//                             linePale: runReq.winnerThisRun.linePale,
//                             third: runReq.winnerThisRun.winners.third,
//                             lineTrip: runReq.winnerThisRun.lineTrip.toFixed(1),
//                             run: runReq.run
//                         });
//                     }else{
//                         setWinner(() => {return { first: '', second: '', lineFirst: 0, linePale: 0 }})
//                     }

//                     setLineUp(true);
//                 }
//             } else {
//                 setLineUp(false);
//             }
//         };

//         socket.on('run', handleRun);

//         // Cleanup al desmontar el componente
//         return () => {
//             socket.off('run', handleRun);
//         };

//     }, [history, run, lineUp, lineForPaleMan, lineForPaleWoman]);

//    /* useEffect(() => {
//         // socket.on('active', (data)=>{
//             socket.emit('active-res',{point:localStorage.getItem('point')})
//         // })
//         socket.on('run', (runReq) => {
//             SetRun(()=>runReq)
//             if (run) if (run.run) if (run.run.code) SetCode(run.run.code.toString().padStart(4, '0'))
//             if (run && run.history) setHistory(value => run.history != value? run.history: value)
//             if(run && run.run && run.run.line) SetLines(()=> run.line)
//             line = run? run.run? run.run.line:undefined:undefined
//             if (line) {
//                 if(!lineUp){
//                 // Asignacion de stilo para macho y hembra
//                 const lineArray = [{ label: 'p1', value: line.d.p1 }, { label: 'p2', value: line.d.p2 }, { label: 'p3', value: line.d.p3 }, { label: 'p4', value: line.d.p4 }, { label: 'p5', value: line.d.p5 }, { label: 'p6', value: line.d.p6 }, { label: 'p7', value: line.d.p7 }, { label: 'p8', value: line.d.p8 }].sort((a, b) => a.value - b.value)
//                 const macho = lineArray[0].label;
//                 const woman = lineArray[7].label;
//                 const paleMan = `${lineArray[1].label}${lineArray[0].label.split('').at(-1)}`;
//                 const paleWoman = `${lineArray[7].label}${lineArray[6].label.split('').at(-1)}`;
//                 const linePaleArray = Object.keys(line)
//                 const linePaleMan = linePaleArray.filter(item => item === paleMan)[0];
//                 const linePaleWoman = linePaleArray.filter(item => item === paleWoman)[0];
//                 setLineForPaleMan(pale => pale !== linePaleMan ? linePaleMan : pale)
//                 setLineForPaleWoman(pale => pale !== linePaleWoman ? linePaleWoman : pale)
//                 setManDog(macho)
//                 setWomanDog(woman)
//                 setPaleManDog(paleMan)
//                 setPaleWomanDog(paleWoman)

//                 // fin
//                 // Winner
//                 if (run && run.winnerThisRun && run.winnerThisRun.winners) {
//                     setWinner(() => {
//                         return {
//                             first: run.winnerThisRun.winners.first,
//                             second: run.winnerThisRun.winners.second,
//                             lineFirst: run.winnerThisRun.lineFirst,
//                             linePale: run.winnerThisRun.linePale
//                         }
//                     })
//                 } else {
//                     // setWinner(() => {return { first: '', second: '', lineFirst: 0, linePale: 0 }})
//                 }
//                 SetLines(line)
//                 setLineUp(true)
//             }
//             }else{
//                 setLineUp(false)
//             }

//         })
//    },[history, line, code, winner, lineUp, lineForPaleMan, lineForPaleWoman])
// */
//  //TODO:: MEDIR EL TIEMPO PARA NO HACER EL RELOAD TODAS LAS CARRERAS
// useEffect(() => {
//   const handleScreen = (data) => {
//     if (number.screen === 3) {
//       if (result) setResult(false);
//       if (localStorage.getItem("reload") === "false") {
//         localStorage.setItem("reload", "true");
//       //  window.location.reload();
//       }
//     } else {
//       if (!result) setResult(true);
//       if (localStorage.getItem("reload") === "true")
//         localStorage.setItem("reload", "false");
//     }

//     if (data && data.jpg && jpg !== data.jpg.value) {
//       setJpg(data.jpg.value || "");
//       setLastWinerJpGral(data.jpg);
//       if (!jpl) {
//         socket.emit("jpl", "");
//         socket.on("jpl-from-server", (jp) => setJpl(jp));
//       }
//       if (!jpc) {
//         socket.emit("jpc", "");
//         socket.on("jpc-from-server", (jp) => setJpc(jp));
//       }
//     }

//     if (data.time) {
//       setTime(data.time);
//     }
//   };

//   socket.on("screen", handleScreen);

//   return () => {
//     socket.off("screen", handleScreen);
//   };
// }, [result, time, jpc, jpl, jpg, lastWinerJpGral, number]);

// /**Function para apostar Jugador */

// const handleShow = ()=> setShow(true);
// const handleClose = ()=> {
// setShow(false)
// setTrip(false)
// };

// const doBetPBylayer = async (type)=>{
// const bodyBet = {
//     dog: { firt: betThisPlayer.dog.toString().split('')[0], second:betThisPlayer.dog>8? betThisPlayer.dog.toString().split('')[1]:'', third:betThisPlayer.dog>87? betThisPlayer.dog.toString().split('')[2]:''},
//     amount: betted>0? betted: 10,
//     type: type,
//     run: run
//   }
//  const res = await fetch(`${url}/ticket`, {
//     method: 'post',
//     headers: headers,
//     body: JSON.stringify([bodyBet])

//   })
//   const data =  await res.json();
// if(data.toString().includes('reada')){
//     setClassMsg('green')
//     setBetThisPlayer({dog:'', pay:0, line:'', run: ''})
// }else{
//     setClassMsg('red')
// }
//     setMsg(data)
//     setTimeout(()=>{
//         setMsg('');
//         setClassMsg('white')
//         setShow(false)
//     },1200)

// }
// const showModalForBet = (optionDog, lineThis)=>{
//   if( localStorage.getItem('level') === '5'){
// setBetThisPlayer({dog:optionDog, line: lineThis})
// setBetted(10)
// handleShow()
// }
// }

//   /**Agregar a monto de apuesta */
//   const AddBetAmount = (value) => {
//     if (betted + value <= 100) {
//       setBetted((betted) => parseFloat(betted) + parseFloat(value))
//     } else {
//       alert('Apuesta Maxima Superada')
//     }
//   }
//   /**Agregar a numero para tripleta */
//   const AddBetAmountNum = (value) => {

//     if (betThisPlayer.dog<87) {
//       console.log(betThisPlayer.pay)
//       setBetThisPlayer((actual) => {return{ ...actual,pay:actual.pay>0? actual.pay*lines.d[`p${value}`]:lines.d[`p${value}`], dog:parseInt(actual.dog.toString() +value.toString())}})
//     } else {
//       alert('Opcion no valida')
//     }
//   }
// const handleChangeTripOption = ()=>{
//   if(tripOption.includes('COLOCA')){
//     setTripOption('EXACTA')
//   }else{
//     setTripOption('COLOCADA')
//   }
// }

//   const cellWidth = dimensions.width*0.3*0.2;
//   const cellHeight = dimensions.height*0.07;
//   const headerHeight = dimensions.height*0.3*.245;

//     if (number.screen === 3 || number.screen === 1) {
//       if (dimenssion.width < dimenssion.height) {
//     return (
//       <div className="fs-1 text-white">
//         Favor colocar el dispositivo de forma Horizontal
//       </div>
//     );
//   } else {
//         return (
//     <Stage width={dimensions.width} height={dimensions.height} ref={stageRef}>
//                     {/**cabecera */}
//         <Layer visible={ready}>
//         <Group x={0} y={0} width={widthCanvas} height={heightCanvas*0.1}>
//           <Rect width={widthCanvas} height={heightCanvas*0.1} fill="transparent" />
//           {/* Left section */}
//           <Group x={0} y={0} width={widthCanvas*0.15} height={heightCanvas*0.1}>
//             <Text text="HISTORIAL" fill="white" align='center' verticalAlign='botton' fontFamily='Arial Dark' fontSize={sizeRef/30}  x={0} y={heightCanvas*0.017} width={widthCanvas*0.1} height={heightCanvas*0.05} stroke={'white'} strokeWidth={1.2}/>
//               <KonvaImage image={triang}  x={widthCanvas*0.001} y={heightCanvas*0.05} width={widthCanvas*0.1} height={heightCanvas*0.05} onClick={()=>  localStorage.getItem('level') === '5' ? window.location.href='/admin-player' : window.location.href='/bet'}  onTouchStart={()=>  localStorage.getItem('level') === '5' ? window.location.href='/admin-player' : window.location.href='/bet'}/>
//           </Group>
//           <Group x={widthCanvas*0.12} y={0} width={widthCanvas*0.2} height={heightCanvas*0.1} >
//             <Text text="PROXIMA CARRERA:" fill="white" fontSize={sizeRef/40}   x={0} y={0} width={widthCanvas*0.2} height={heightCanvas*0.05} align='center' verticalAlign='middle' stroke={'white'} strokeWidth={2}/>
//             <Text text={code} fill="white" fontSize={sizeRef/30} align='center' verticalAlign='middle' x={0} y={heightCanvas*0.05} width={widthCanvas*0.2} height={heightCanvas*0.05} stroke={'white'} strokeWidth={1.2}/>
//           </Group>

//           {/* Right section */}
//           <Group x={widthCanvas*0.3} y={0} width={widthCanvas*0.7} height={heightCanvas*0.1}>
//           <Group x={widthCanvas*0.15} y={0} width={widthCanvas*0.2} height={heightCanvas*0.05} visible={localStorage.getItem('level')==='5'}>
//             <Rect  x={0} y={0} width={widthCanvas*0.2} height={heightCanvas*0.05} fill='brown' cornerRadius={10}/>
//             <Text text={'Tripleta'}  x={0} y={0} width={widthCanvas*0.2} height={heightCanvas*0.05} fill='white' cornerRadius={10} align="center" verticalAlign='middle' fontSize={sizeRef/25} onTouchStart={()=>setTrip(true)} />
//           </Group>
//           <Group x={widthCanvas- widthCanvas*0.5} y={0} width={widthCanvas*0.7} height={heightCanvas*0.05}>
//             <Text text={''} fill="red" verticalAlign='top' fontFamily='Arial Dark' fontSize={sizeRef/25} x={0} y={0} width={widthCanvas*0.3} height={heightCanvas*0.05}/>
//             <Text text={''} fill="white" verticalAlign='top' fontFamily='Arial Dark' fontSize={sizeRef/25} x={widthCanvas*0.045} y={0} width={widthCanvas*0.3} height={heightCanvas*0.05}/>
//             <KonvaImage
//             image={imgLogo}
//             x={0}
//             y={0}
//             width={widthCanvas*0.15}
//             height={heightCanvas*0.07}
//             visible={true}
//             />
//           </Group>
//           <Group x={0} y={heightCanvas*0.06} width={widthCanvas*0.7} height={heightCanvas*0.05}>
//           <Group x={0}  y={0} width={widthCanvas*0.05} height={heightCanvas*0.03}>
//             <Rect x={0} y={0} fill="blue" cornerRadius={10} stroke="grey"  width={widthCanvas*0.05} height={heightCanvas*0.03}/>
//             <Text text={`${time} s`} fill="white" fontSize={sizeRef/60} align="center" verticalAlign="middle" width={widthCanvas*0.05} height={heightCanvas*0.03}  stroke={'white'} strokeWidth={1.2}/>
//           </Group>
//           <Group x={widthCanvas*0.06} y={heightCanvas*0.01} width={widthCanvas*0.65}>
//             <Rect x={0} y={0} height={heightCanvas*0.02} fill="gray" cornerRadius={5} stroke="yellow" width={widthCanvas*0.62}/>
//             <Rect x={widthCanvas*0.001} y={heightCanvas*0.003} width={(time / 240) * widthCanvas*0.62} height={heightCanvas*0.013} fill={rgb[parseInt(24-time/10)]} cornerRadius={5} />
//           </Group>
//           </Group>
//           </Group>
//         </Group>
//       </Layer>
//      {/**Historial */}
//      <Layer visible={ready}>
//         <Group x={0} y={heightCanvas*0.1} width={widthCanvas*0.3} height={heightCanvas*0.6}>
//         <Rect x={0} y={0} width={widthCanvas*0.25} height={heightCanvas*0.635} fill={'#e0e0e0'} cornerRadius={10}/>
//         <Group x={0} y={0} width={cellWidth} height={cellHeight}>
//         <Group x={0} y={0} width={1.5*cellWidth} height={cellHeight}>
//           <Rect
//             x={0}
//             y={0}
//             width={1.7*cellWidth}
//             height={cellHeight}
//             fill="darkblue"
//           />
//           <Text
//             x={0}
//             y={0}
//             text="Carreras"
//             fontSize={sizeRef/40}
//             fill="white"
//             width={1.7*cellWidth}
//             height={cellHeight}
//             align='center'
//             verticalAlign='middle'
//             stroke={'white'}
//             strokeWidth={1.2}

//           />
//         </Group>
//           <Group x={1.7*cellWidth} y={0} width={cellWidth} height={cellHeight}>
//           <Rect
//             x={0}
//             y={0}
//             width={cellWidth}
//             height={cellHeight}
//             fill="darkblue"

//           />
//           <Text
//             x={0}
//             y={0}
//             text="1ER"
//             fontSize={sizeRef/40}
//             fill="white"
//             width={cellWidth}
//             height={cellHeight}
//             align='center'
//             verticalAlign='middle'
//             stroke={'white'}
//             strokeWidth={1.2}
//           />
//           </Group>
//           <Group x={2.5*cellWidth} y={0} width={cellWidth} height={cellHeight}>
//           <Rect
//             x={0}
//             y={0}
//             width={cellWidth}
//             height={cellHeight}
//             fill="darkblue"

//           />
//           <Text
//             x={0}
//             y={0}
//             text="2DO"
//             fontSize={sizeRef/40}
//             fill="white"
//             align='center'
//             verticalAlign='middle'
//             width={cellWidth}
//             height={cellHeight}
//             stroke={'white'}
//             strokeWidth={1.2}
//           />
//         </Group>
//         <Group x={3.5*cellWidth} y={0} width={cellWidth} height={cellHeight}>
//           <Rect
//             x={0}
//             y={0}
//             width={cellHeight}
//             height={cellHeight}
//             fill="darkblue"

//           />
//           <Text
//             x={0}
//             y={0}
//             text="3ER"
//             fontSize={sizeRef/40}
//             fill="white"
//             align='center'
//             verticalAlign='middle'
//             width={cellHeight}
//             height={cellHeight}
//             stroke={'white'}
//             strokeWidth={1.2}
//           />
//         </Group>
//     </Group>
//      {history && history.map((item, rowIndex) =>
//         <Group key={rowIndex} y={headerHeight + rowIndex * cellHeight} width={widthCanvas*0.3}>
//           <Rect
//             x={0}
//             y={0}
//             width={1.7*cellWidth}
//             height={cellHeight*0.9}
//             fill='white'
//             stroke="grey"
//             cornerRadius={10}
//             padding={sizeRef*0.1}
//           />
//           {item?.factor>1 &&<Group x={0} y={0} width={0.1*cellWidth} height={cellHeight}>
//             <Rect
//             x={0.03*cellWidth}
//             y={0.05*cellHeight}
//             width={0.3*cellWidth}
//             height={0.8*cellHeight}
//             fill='red'
//             stroke="red"
//             padding={sizeRef*0.01}
//             cornerRadius={5}
//           />
//             <Text
//            x={0.03*cellWidth}
//            y={0.1*cellHeight}
//            width={0.3*cellWidth}
//            height={0.4*cellHeight}
//           text={'BONO'}
//           fontFamily='Courier New'
//           fontSize={sizeRef/80}
//           fill="green"
//           verticalAlign='botton'
//           align='center'
//           stroke={'greenyellow'}
//           strokeWidth={1.2}
//         />
//           <Text
//            x={0.03*cellWidth}
//            y={0.4*cellHeight}
//            width={0.3*cellWidth}
//            height={0.4*cellHeight}
//           text={item?.factor===3?'X3':'X2'}
//           fontFamily='Arial Dark'
//           fontSize={sizeRef/50}
//           fill="white"
//           verticalAlign='botton'
//           align='center'
//           stroke={'white'}
//           strokeWidth={1.2}
//         />
//         </Group>
//           }
//           <Text
//             x={0}
//             y={0}
//             text={item.code.toString().padStart(6, '0')}
//             fontFamily='Arial Dark'
//             fontSize={sizeRef/30}
//             fill="black"
//             verticalAlign='middle'
//             align='center'
//             width={1.7*cellWidth}
//             height={cellHeight*.9}
//             stroke={'black'}
//             strokeWidth={1.2}
//           />
//           <Rect
//             x={2*cellWidth}
//             y={0}
//             width={cellHeight*0.9}
//             height={cellHeight*0.9}
//             fillLinearGradientStartPoint={{ x: 0, y: 0 }}
//             fillLinearGradientEndPoint={{ x: 0, y: 100 }}
//             fillLinearGradientColorStops={dogs[item.winner.fisrt-1].bg}
//             stroke="grey"
//             cornerRadius={10}
//             padding={sizeRef*0.01}
//           />
//           <Text
//             x={2*cellWidth}
//             y={0}
//             text={item.winner.fisrt}
//             fontFamily='Arial Dark'
//             fontSize={sizeRef/30}
//             fill={dogs[item.winner.fisrt-1].color}
//             verticalAlign='middle'
//             align='center'
//             width={cellHeight*0.9}
//             height={cellHeight*0.9}
//             stroke={dogs[item.winner.fisrt-1].color}
//             strokeWidth={2}
//           />
//           <Rect
//             x={cellWidth*2.7}
//             y={0}
//             width={cellHeight*0.9}
//             height={cellHeight*0.9}
//             fillLinearGradientStartPoint={{ x: 0, y: 0 }}
//             fillLinearGradientEndPoint={{ x: 0, y: 100 }}
//             fillLinearGradientColorStops={dogs[item.winner.second-1].bg}
//             stroke="grey"
//             cornerRadius={10}
//             padding={sizeRef*0.01}
//           />
//           <Text
//             x={cellWidth *2.7}
//             y={0}
//             text={item.winner.second}
//             fontFamily='Arial Dark'
//             fontSize={sizeRef/30}
//             fill={dogs[item.winner.second-1].color}
//             verticalAlign='middle'
//             align='center'
//             width={cellHeight*0.9}
//             height={cellHeight*0.9}
//             stroke={dogs[item.winner.second-1].color}
//             strokeWidth={2}
//           />
//             <Rect
//             x={cellWidth*3.5}
//             y={0}
//             width={cellHeight*.9}
//             height={cellHeight*.9}
//             fillLinearGradientStartPoint={{ x: 0, y: 0 }}
//             fillLinearGradientEndPoint={{ x: 0, y: 100 }}
//             fillLinearGradientColorStops={dogs[item.winner.third-1].bg}
//             stroke="grey"
//             cornerRadius={10}
//             padding={sizeRef*0.01}
//           />
//             <Text
//             x={cellWidth * 3.5}
//             y={0}
//             text={item.winner.third}
//             fontFamily='Arial Dark'
//             fontSize={sizeRef/30}
//             fill={dogs[item.winner.third-1].color}
//             verticalAlign='middle'
//             align='center'
//             width={cellHeight*.9}
//             height={cellHeight*.9}
//             stroke={dogs[item.winner.third-1].color}
//             strokeWidth={2}
//           />
//         </Group>)}
//     </Group>

//     <Group x={widthCanvas*0.3} y={heightCanvas*0.1} width={widthCanvas*0.7} height={heightCanvas*0.8}>
//     <Rect x={0} y={0} width={widthCanvas*0.682} height={heightCanvas*0.63} fill={'#e0e0e0'} cornerRadius={10}/>
// { data.map((row, rowIndex) =>
//           row.map((cell, colIndex) => {
//             const withCellLine = widthCanvas*0.69/9
//             const heightCellLine = heightCanvas*0.6/9
//             return (
//               <React.Fragment key={`${rowIndex}-${colIndex}`}>
//                 <Rect
//                   x={colIndex * withCellLine}
//                   y={rowIndex * cellHeight}
//                   width={withCellLine*0.95}
//                   height={cellHeight*0.95}
//                   fillLinearGradientStartPoint={{ x: 0, y: 0 }}
//                   fillLinearGradientEndPoint={{ x: 0, y: 100 }}
//                   fillLinearGradientColorStops={rowIndex===0? colIndex===0?[0,'#000']:dogs[colIndex-1].bg:colIndex===0? dogs[rowIndex-1].bg : cell===manDog?[0,'skyblue']:cell.toString().includes('p')?parseInt(cell.toString().replace('p',''))<=8? womanDog===cell? [0,'pink']:[0,'#fff']:[0,'#fff']:[0,'#fff']}
//                   stroke={rowIndex!==0  && rowIndex===colIndex? `${dogs[rowIndex-1].stroke}`:'gray'}
//                   strokeWidth={rowIndex!==0  && rowIndex===colIndex? 2:0.5}
//                   cornerRadius={10}
//                   padding={10}
//                 />
//                 <Text
//                   x={colIndex * withCellLine}
//                   y={rowIndex * cellHeight}
//                   width={withCellLine*0.9}
//                   height={cellHeight*0.9}
//                   align="center"
//                   verticalAlign="middle"
//                   text={cell.toString().includes('p')?parseInt(cell.toString().replace('p',''))<=8? lines?parseFloat((lines.d[`${cell.toString()}`])).toFixed(1):'--':lines?parseFloat((lines[`${cell.toString()}`])).toFixed(1):'--':cell}
//                   fill={rowIndex===0? colIndex===0?'white':dogs[colIndex-1].color:colIndex===0? dogs[rowIndex-1].color : cell===manPaleDog?'blue':cell===womanPaleDog? 'red':'black'}
//                   stroke={rowIndex===0? colIndex===0?'white':dogs[colIndex-1].color:colIndex===0? dogs[rowIndex-1].color : cell===manPaleDog?'blue':cell===womanPaleDog? 'red':'black'}
//                   fontSize={rowIndex===0 && colIndex===0? sizeRef/60:rowIndex===0 && colIndex!==0?sizeRef/30:rowIndex!==0 && colIndex===0?sizeRef/30: sizeRef/40}
//                   strokeWidth={rowIndex===0 && colIndex!==0? 1.2:rowIndex!==0 && colIndex===0? 1.5:1}
//                   onTouchStart={()=>cell.includes('p')? showModalForBet(parseInt(cell.toString().replace('p','')),parseInt(cell.toString().replace('p',''))<=8? parseFloat((lines.d[`${cell.toString()}`])).toFixed(1):parseFloat((lines[`${cell.toString()}`])).toFixed(1)):''}
//                 />
//               </React.Fragment>
//             );
//           })
//         )}

//     </Group>
//     </Layer>
//                      {/**jackpot*/}
//     <Layer visible={ready}>
//         <Group x={0} y={heightCanvas*0.75} width={widthCanvas} height={heightCanvas*0.15}>
//           <Group x={0} y={0} width={widthCanvas*0.5} height={heightCanvas*0.15}>
//             <Text
//             text='Últimos JackPots Pagados'
//             width={widthCanvas*0.5}
//             height={heightCanvas*0.05}
//             fill='white'
//             fontSize={sizeRef/30}
//             align='center'
//             stroke='white'
//             verticalAlign='middle'
//             strokeWidth={1.2}
//             cornerRadius={10}
//             />
//           <Group x={0} y={heightCanvas*0.05} width={widthCanvas*0.5/3} height={heightCanvas*0.15}>
//           <Rect x={0} y={0} width={widthCanvas*0.5/3} height={heightCanvas*0.15} fill="white" stroke="grey" strokeWidth={1} cornerRadius={5} />
//           <Rect x={0} y={0} width={widthCanvas*0.5/3} height={heightCanvas*0.05} fill="orange" stroke="grey" strokeWidth={1} cornerRadius={5} />
//               <Text text="Jackpot Local" x={0} y={0} width={widthCanvas*0.48/3} height={heightCanvas*0.05} fill="black" fontSize={sizeRef/40} align='center' padding={sizeRef*0.01} verticalAlign='middle' stroke={'black'} strokeWidth={1}/>
//               <Text text={`Ticket: ${jpl ? (jpl.ticket === 0 ? '----' : jpl.ticket) : '----'}`} fill="black" fontSize={sizeRef/40}  align='center' padding={sizeRef*0.01} verticalAlign='middle' y={heightCanvas*0.05} width={widthCanvas*0.48/3} height={heightCanvas*0.1} />
//             </Group>
//             <Group x={widthCanvas*0.51/3} y={heightCanvas*0.05} width={widthCanvas*0.5/3} height={heightCanvas*0.15}>
//                 <Rect x={0} y={0} width={widthCanvas*0.5/3} height={heightCanvas*0.15} fill="white" stroke="grey" strokeWidth={1} cornerRadius={5} />
//                 <Rect x={0} y={0} width={widthCanvas*0.5/3} height={heightCanvas*0.05} fill="orange" stroke="grey" strokeWidth={1} cornerRadius={5} />
//                 <Text text="Jackpot Consorcio" x={0} y={0} width={widthCanvas*0.48/3} height={heightCanvas*0.05} fill="black" fontSize={sizeRef/40} align='center' verticalAlign='middle' stroke={'black'} strokeWidth={1}/>
//                 <Text text={`Ticket: ${jpc ? (jpc.ticket === 0 ? '----' : jpc.ticket) : '----'}`}x={0} y={heightCanvas*0.05} width={widthCanvas*0.48/3} height={heightCanvas*0.04} fill="black" fontSize={sizeRef/40} align='center'/>
//                 <Text text={`${jpc && jpc.point ? jpc.point?.replace('SPORTS','').replace('SPORT','') : '------'}`}x={0} y={heightCanvas*0.09} width={widthCanvas*0.48/3} height={heightCanvas*0.03} fill="black" fontSize={sizeRef/40} align='center'/>
//                 <Text text={`${jpc && jpc.point ? jpc?.address?.city  : '------'}`}x={0} y={heightCanvas*0.12} width={widthCanvas*0.48/3} height={heightCanvas*0.03} fill="black" fontSize={sizeRef/50} align='center'/>
//             </Group>
//             <Group x={2*widthCanvas*0.51/3} y={heightCanvas*0.05} width={widthCanvas*0.5/3} height={heightCanvas*0.15}>
//                 <Rect x={0} y={0} width={widthCanvas*0.5/3} height={heightCanvas*0.15} fill="white" stroke="grey" strokeWidth={1} cornerRadius={5} />
//                 <Rect x={0} y={0} width={widthCanvas*0.5/3} height={heightCanvas*0.05} fill="orange" stroke="grey" strokeWidth={1} cornerRadius={5} />
//                 <Text text="Jackpot General" x={0} y={0} width={widthCanvas*0.48/3} height={heightCanvas*0.05} fill="black" fontSize={sizeRef/40} align='center' verticalAlign='middle' stroke={'black'} strokeWidth={1}/>
//                 <Text text={`Ticket: ${lastWinerJpGral && lastWinerJpGral?.ticket!==0? lastWinerJpGral?.ticket : '-----'}`} fill="black" fontSize={sizeRef/40} y={heightCanvas*0.05} width={widthCanvas*0.48/3} height={heightCanvas*0.04} align='center'/>
//                 <Text text={` ${lastWinerJpGral && lastWinerJpGral.point? lastWinerJpGral.point?.replace('SPORTS','').replace('SPORT','')  : '-----'}`} x={0} y={heightCanvas*0.09} width={widthCanvas*0.48/3}  height={heightCanvas*0.03}fill="black" fontSize={sizeRef/40} align='center'  verticalAlign='middle' />
//                 <Text text={` ${lastWinerJpGral && lastWinerJpGral.address ? lastWinerJpGral?.address?.city  : '-----'}`} x={0} y={heightCanvas*0.12} width={widthCanvas*0.48/3}  height={heightCanvas*0.03} fill="black" fontSize={sizeRef/50} align='center'  verticalAlign='middle'/>
//             </Group>
//           </Group>

//           <Group x={widthCanvas*0.51} y={0} width={widthCanvas*0.48} height={heightCanvas*0.15}>
//             <Text
//             text='JackPots Actuales'
//             width={widthCanvas*0.5}
//             height={heightCanvas*0.05}
//             fill='white'
//             fontSize={sizeRef/30}
//             align='center'
//             verticalAlign='middle'
//             stroke='white'
//             strokeWidth={1.2}
//             cornerRadius={10}
//             />
//         <Group x={0} y={heightCanvas*0.05} width={widthCanvas*0.48/3} height={heightCanvas*0.15}>
//           <Rect x={0} y={0} width={widthCanvas*0.48/3} height={heightCanvas*0.15} fill="white" stroke="grey" strokeWidth={1} cornerRadius={5} />
//           <Rect x={0} y={0} width={widthCanvas*0.48/3} height={heightCanvas*0.05} fill="blue" stroke="grey" strokeWidth={1} cornerRadius={5} />
//           <Text x={0} y={0} text="Jackpot Local" width={widthCanvas*0.48/3} height={headerHeight*0.05} fontSize={sizeRef/40} padding={sizeRef*0.01}fill="white" align='center' stroke={'white'} strokeWidth={1.2}/>
//           <Text x={0} y={heightCanvas*0.05} width={widthCanvas*0.5/3} height={heightCanvas*0.1} verticalAlign='middle' text={jpl ? parseFloat(jpl.value).toLocaleString('en-EN',{
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2,
// }) : '0.00'} fill="black" align='center' fontSize={sizeRef/30} padding={sizeRef*0.01} stroke={'black'} strokeWidth={1.2}/>
//         </Group>
//         <Group x={widthCanvas*0.49/3} y={heightCanvas*0.05} width={widthCanvas*0.5/3} height={heightCanvas*0.15}>
//           <Rect x={0} y={0} width={widthCanvas*0.48/3} height={heightCanvas*0.15} fill="white" stroke="grey" strokeWidth={1} cornerRadius={5} />
//           <Rect x={0} y={0} width={widthCanvas*0.48/3} height={heightCanvas*0.05} fill="blue" stroke="grey" strokeWidth={1} cornerRadius={5} />
//           <Text x={0} y={0} text="Jackpot Consorcio" width={widthCanvas*0.48/3} height={headerHeight*0.05}  fontSize={sizeRef/40} padding={sizeRef*0.01} fill="white" align='center'stroke={'white'} strokeWidth={1.2}/>
//           <Text x={0} y={heightCanvas*0.05} width={widthCanvas*0.5/3} height={heightCanvas*0.1} verticalAlign='middle' text={jpc ? parseFloat(jpc.value).toLocaleString('en-EN',{
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2,
// }) : '0.00'} fill="black" align='center' fontSize={sizeRef/30} padding={sizeRef*0.01} stroke={'black'} strokeWidth={1.2}/>
//         </Group>
//         <Group x={2*widthCanvas*0.49/3} y={heightCanvas*0.05} width={widthCanvas*0.5/3} height={heightCanvas*0.15}>
//           <Rect x={0} y={0} width={widthCanvas*0.48/3} height={heightCanvas*0.15} fill="white" stroke="grey" strokeWidth={1} cornerRadius={5} />
//           <Rect x={0} y={0} width={widthCanvas*0.48/3} height={heightCanvas*0.05} fill="blue" stroke="grey" strokeWidth={1} cornerRadius={5} />
//           <Text x={0} y={0} text="Jackpot General" width={widthCanvas*0.48/3} height={headerHeight*0.05}  fontSize={sizeRef/40} padding={sizeRef*0.01} fill="white" align='center' stroke={'white'} strokeWidth={1.2}/>
//           <Text x={0} y={heightCanvas*0.05} width={widthCanvas*0.5/3} height={heightCanvas*0.1} verticalAlign='middle' text={jpg ? parseFloat(jpg).toLocaleString('en-EN',{
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2,
// }) : '0.00'} fill="black" align='center' fontSize={sizeRef/30} padding={sizeRef*0.01} stroke={'black'} strokeWidth={1.2}/>
//         </Group>
//           </Group>
//         </Group>

//         {result===false && winner.first!=='' && (  <Group x={0} y={heightCanvas*0.01} width={widthCanvas*0.99} height={heightCanvas*0.99}>
//             <Rect x={0} y={0} width={widthCanvas*0.99} height={heightCanvas*0.99} fill='#000' stroke={'red'} strokeWidth={5} cornerRadius={10}/>
//             <Text text="GANADORES" x={0} y={heightCanvas*0.1} fontSize={sizeRef/10} fill='orange' align="center" width={widthCanvas} height={heightCanvas*0.2} strokeWidth={3}/>
//             {/* Ejemplo de cómo dibujar un rectángulo y texto */}
//             <Group x={0} y={heightCanvas*0.25} width={widthCanvas} height={heightCanvas*0.75}>
//             <Rect x={widthCanvas*0.01} y={0} width={widthCanvas*0.35} height={heightCanvas*0.1} fill="green" cornerRadius={10}/>
//             <Text text="GANADOR" x={widthCanvas*0.01} y={0} fontSize={sizeRef/25} fill="white" align='center' verticalAlign='middle' width={widthCanvas*0.35} height={heightCanvas*0.1} stroke={'white'} strokeWidth={1.2}/>
//             <Rect x={widthCanvas*0.43} y={0}  fillLinearGradientStartPoint={{ x: 0, y: 0 }} fillLinearGradientEndPoint={{ x: 0, y: 100 }} fillLinearGradientColorStops={dogs[winner.first-1].bg}  width={widthCanvas*0.35} height={heightCanvas*0.1} cornerRadius={10}/>
//             <Text text={winner.first || '---'}x={widthCanvas*0.43} y={heightCanvas*0.01} fontSize={sizeRef/25} fill={dogs[winner.first-1].color}  width={widthCanvas*0.35} height={heightCanvas*0.1} align='center' verticalAlign='middle' stroke={dogs[winner.first-1].color} strokeWidth={1.2}/>
//             <Rect x={widthCanvas*0.83} y={0}  fill="orange" width={widthCanvas*0.15} height={heightCanvas*0.1} cornerRadius={10}/>
//             <Text text={winner.lineFirst} x={widthCanvas*0.83}y={heightCanvas*0.01} width={widthCanvas*0.15} height={heightCanvas*0.1}fontSize={sizeRef/25} align='center' verticalAlign='middle' fill="black" stroke={'black'} strokeWidth={1.2}/>
//            </Group>
//            <Group x={0} y={heightCanvas*0.45} width={widthCanvas} height={heightCanvas*0.75}>
//             <Rect x={widthCanvas*0.01} y={0} width={widthCanvas*0.35} height={heightCanvas*0.1} fill="green" cornerRadius={10}/>
//             <Text text="PALE" x={widthCanvas*0.01} y={0} fontSize={sizeRef/25} fill="white" align='center' verticalAlign='middle' width={widthCanvas*0.35} height={heightCanvas*0.1} stroke={'white'} strokeWidth={1.2}/>
//             <Rect x={widthCanvas*0.43} y={0}  fillLinearGradientStartPoint={{ x: 0, y: 0 }} fillLinearGradientEndPoint={{ x: 0, y: 100 }} fillLinearGradientColorStops={dogs[winner.first-1].bg}  width={widthCanvas*0.15} height={heightCanvas*0.1} cornerRadius={10}/>
//             <Text text={winner.first || '---'}x={widthCanvas*0.43} y={heightCanvas*0.01} fontSize={sizeRef/25} fill={dogs[winner.first-1].color}  width={widthCanvas*0.15} height={heightCanvas*0.1} align='center' verticalAlign='middle' stroke={dogs[winner.first-1].color} strokeWidth={1.2}/>
//             <Rect x={widthCanvas*0.62} y={0}  fillLinearGradientStartPoint={{ x: 0, y: 0 }} fillLinearGradientEndPoint={{ x: 0, y: 100 }} fillLinearGradientColorStops={dogs[winner.second-1].bg}  width={widthCanvas*0.15} height={heightCanvas*0.1} cornerRadius={10}/>
//             <Text text={winner.second || '---'}x={widthCanvas*0.62} y={heightCanvas*0.01} fontSize={sizeRef/25} fill={dogs[winner.second-1].color}  width={widthCanvas*0.15} height={heightCanvas*0.1} align='center' verticalAlign='middle' stroke={dogs[winner.second-1].color} strokeWidth={1.2}/>
//             <Rect x={widthCanvas*0.83} y={0}  fill="orange" width={widthCanvas*0.15} height={heightCanvas*0.1} cornerRadius={10}/>
//             <Text text={winner.linePale} x={widthCanvas*0.83}y={0} width={widthCanvas*0.15} height={heightCanvas*0.1}fontSize={sizeRef/25} align='center' verticalAlign='middle' fill="black" stroke={'black'} strokeWidth={1.2}/>
//            </Group>
//            <Group x={0} y={heightCanvas*0.65} width={widthCanvas} height={heightCanvas*0.75}>
//             <Rect x={widthCanvas*0.01} y={0} width={widthCanvas*0.35} height={heightCanvas*0.1} fill="green" cornerRadius={10}/>
//             <Text text="TRIPLETA" x={widthCanvas*0.01} y={0} fontSize={sizeRef/25} fill="white" align='center' verticalAlign='middle' width={widthCanvas*0.35} height={heightCanvas*0.1} stroke={'white'} strokeWidth={1.2}/>
//             <Rect x={widthCanvas*0.43} y={0}  fillLinearGradientStartPoint={{ x: 0, y: 0 }} fillLinearGradientEndPoint={{ x: 0, y: 100 }} fillLinearGradientColorStops={dogs[winner.first-1].bg} width={widthCanvas*0.1} height={heightCanvas*0.1} cornerRadius={10}/>
//             <Text text={winner.first || '---'}x={widthCanvas*0.43} y={heightCanvas*0.01} fontSize={sizeRef/25} fill={dogs[winner.first-1].color} width={widthCanvas*0.1} height={heightCanvas*0.1} align='center' verticalAlign='middle'stroke={dogs[winner.first-1].color} strokeWidth={1.2}/>
//             <Rect x={widthCanvas*0.55} y={0}  fillLinearGradientStartPoint={{ x: 0, y: 0 }} fillLinearGradientEndPoint={{ x: 0, y: 100 }} fillLinearGradientColorStops={dogs[winner.second-1].bg} width={widthCanvas*0.1} height={heightCanvas*0.1} cornerRadius={10}/>
//             <Text text={winner.second || '---'}x={widthCanvas*0.55} y={heightCanvas*0.01} fontSize={sizeRef/25} fill={dogs[winner.second-1].color} width={widthCanvas*0.1} height={heightCanvas*0.1} align='center' verticalAlign='middle'stroke={dogs[winner.second-1].color} strokeWidth={1.2}/>
//             <Rect x={widthCanvas*0.67} y={0}  fillLinearGradientStartPoint={{ x: 0, y: 0 }} fillLinearGradientEndPoint={{ x: 0, y: 100 }} fillLinearGradientColorStops={dogs[winner.third-1].bg}width={widthCanvas*0.1} height={heightCanvas*0.1} cornerRadius={10}/>
//             <Text text={winner.third || '---'}x={widthCanvas*0.67} y={heightCanvas*0.01} fontSize={sizeRef/25} fill={dogs[winner.third-1].color}  width={widthCanvas*0.1} height={heightCanvas*0.1} align='center' verticalAlign='middle'stroke={dogs[winner.third-1].color} strokeWidth={1.2}/>
//             <Rect x={widthCanvas*0.83} y={0}  fill="orange" width={widthCanvas*0.15} height={heightCanvas*0.1} cornerRadius={10}/>
//             <Text text={winner?.lineTrip} x={widthCanvas*0.83}y={heightCanvas*0.01} width={widthCanvas*0.15} height={heightCanvas*0.1}fontSize={sizeRef/25} align='center' verticalAlign='middle' fill="black" stroke={'black'} strokeWidth={1.2}/>
//            </Group>

//       </Group>
//     )}
// <Group x={0} y={heightCanvas-heightCanvas*0.05} width={widthCanvas} height={heightCanvas*0.05} visible={localStorage.getItem('level'!=='5')}>
// <KonvaImage
// image={wsImg}
//   x={widthCanvas*0.75}
//   y={heightCanvas*0.00}
//   width={widthCanvas*0.04}
//   height={heightCanvas*0.04}
// />
// <Text text='Soporte Técnico y Reclamaciones en Rep. Dom. (849) 863-1426' width={widthCanvas} height={heightCanvas*0.05} fill='yellow' align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
// </Group>
//  </Layer>
//       <Layer visible={show || trip}>
//        { trip?        <Group x={widthCanvas*0.1} y={heightCanvas*0.1} width={window.innerWidth*0.8} height={window.innerHeight*0.8}>
//           <Rect width={window.innerWidth*0.8} height={window.innerHeight*0.8} fill="white" shadowBlur={10} />
//           <Rect x={widthCanvas*0.75} y={heightCanvas*0.01} width={window.innerWidth*0.05} height={window.innerHeight*0.05} fill="white" stroke={'black'} cornerRadius={5} onTouchStart={handleClose}/>
//           <Text text="X" x={widthCanvas*0.75} y={heightCanvas*0.01}  width={window.innerWidth*0.05} height={window.innerHeight*0.05} align='center' verticalAlign='middle' fontSize={sizeRef/30} onTouchStart={handleClose}/>
//           <Text text="Nueva Jugada Tripleta" fontSize={sizeRef/40} x={widthCanvas*0.01} y={heightCanvas*0.05}  width={window.innerWidth*0.8}/>
//           <Rect x={widthCanvas*0.4} y={heightCanvas*0.01} width={window.innerWidth*0.25} height={window.innerHeight*0.05} fill={classMsg}  cornerRadius={10}/>
//           <Text text={msg} x={widthCanvas*0.4} y={heightCanvas*0.01} width={window.innerWidth*0.25} height={window.innerHeight*0.05} fontSize={sizeRef/30} fill={classMsg==='red'?'yellow': 'white'} align='center' verticalAlign='middle' />
//           <Group x={widthCanvas*0.55} y={heightCanvas*0.1} width={window.innerWidth*0.2} height={window.innerHeight*0.2} onTouchStart={handleChangeTripOption}>
//           <Rect x={0} y={0} width={window.innerWidth*0.15} height={window.innerHeight*0.04} fill={tripOption.includes('COL')?'blue':'red'}  cornerRadius={10}/>
//             <Text text={tripOption} x={0} y={0} width={window.innerWidth*0.15} height={window.innerHeight*0.04} fill={tripOption.includes('COL')?'white':'yellow'}   align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//           </Group>
//           <Group x={widthCanvas*0.1} y={heightCanvas*0.15} width={window.innerWidth*0.6} height={window.innerHeight*0.2}>
//           <Group x={0} y={0} width={window.innerWidth*0.6} height={window.innerHeight*0.2}>
//           <Rect x={0} y={0} width={window.innerWidth*0.6} height={window.innerHeight*0.05} fill="gray" />
//             <Text text="Jugadas" x={0} y={0} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//             <Text text="Apostado" x={window.innerWidth*0.15} y={0} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//             <Text text="Paga"     x={window.innerWidth*0.3} y={0} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//             <Text text="Ganancia"  x={window.innerWidth*0.45} y={0} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//           </Group>
//           <Group x={0} y={heightCanvas*0.01} width={window.innerWidth*0.6} height={window.innerHeight*0.2}>
//             <Text text={betThisPlayer.dog >0? `${tripOption.includes('COL')?'TC':'TE'}-${betThisPlayer.dog.toString().split('')[0]}-${betThisPlayer.dog.toString().split('')[1]?betThisPlayer.dog.toString().split('')[1]:''}-${betThisPlayer.dog.toString().split('')[2]?betThisPlayer.dog.toString().split('')[2]:''}` :''} x={0} y={window.innerHeight*0.05} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//             <Text text={`${betted}`} x={window.innerWidth*0.15} y={window.innerHeight*0.05} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40} />
//             <Text text={`${tripOption.includes('EXACTA')?betThisPlayer.pay.toFixed(1):(betThisPlayer.pay/3).toFixed(1)}`} x={window.innerWidth*0.3} y={window.innerHeight*0.05} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40} />
//             <Text text={`${tripOption.includes('EXACTA')?(betThisPlayer.pay * betted).toFixed(0):(betThisPlayer.pay * betted/3).toFixed(0)}`} x={window.innerWidth*0.45} y={window.innerHeight*0.05} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//             </Group>
//           </Group>
//           <Group  x={widthCanvas*0.075} y={heightCanvas*0.27}  width={widthCanvas*0.8} height={heightCanvas*0.075}>
//             {[1,2,3,4,5,6,7,8].map((num, index) => (
//               <Rect
//                 key={index}
//                 x={widthCanvas*0.05+index*widthCanvas*0.071}
//                 width={widthCanvas*0.06}
//                 height={heightCanvas*0.075}
//                 fill='back'
//               />
//             ))}
//             {[1,2,3,4,5,6,7,8].map((num, index) => (
//               <Text key={index} text={(lines?.d[`p${num}`]).toFixed(1)}
//               x={widthCanvas*0.05+index*widthCanvas*0.071}
//               width={widthCanvas*0.06}
//               height={heightCanvas*0.075}
//               align='center'
//               verticalAlign='middle'
//               fontSize={sizeRef/25}
//               onTouchStart={() => AddBetAmountNum(num)}
//               fill={'yellow'}
//               />
//             ))}
//           </Group>
//             <Group  x={widthCanvas*0.075} y={heightCanvas*0.35}  width={widthCanvas*0.8} height={heightCanvas*0.2}>
//             {[1,2,3,4,5,6,7,8].map((num, index) => (
//               <Rect
//                 key={index}
//                 x={widthCanvas*0.05+index*widthCanvas*0.071}
//                 width={widthCanvas*0.06}
//                 height={heightCanvas*0.1}
//                 fillLinearGradientStartPoint={{ x: 0, y: 0 }}
//                 fillLinearGradientEndPoint={{ x: 0, y: 100 }}
//                 fillLinearGradientColorStops={dogs[index].bg}
//                 onTouchStart={() => AddBetAmountNum(num)}
//                 cornerRadius={5}
//                 stroke={'blue'}
//               />
//             ))}
//             {[1,2,3,4,5,6,7,8].map((num, index) => (
//               <Text key={index} text={num}
//               x={widthCanvas*0.05+index*widthCanvas*0.071}
//               width={widthCanvas*0.06}
//               height={heightCanvas*0.1}
//               align='center'
//               verticalAlign='middle'
//               fontSize={sizeRef/25}
//               onTouchStart={() => AddBetAmountNum(num)}
//               fill={dogs[index].color}
//               />
//             ))}
//           </Group>
//           <Group  x={widthCanvas*0.025} y={heightCanvas*0.5}  width={widthCanvas*0.8} height={heightCanvas*0.2}>
//             {[10, 15, 25, 50, 100].map((amount, index) => (
//               <Rect
//                 key={index}
//                 x={widthCanvas*0.11+index*widthCanvas*0.11}
//                 width={widthCanvas*0.1}
//                 height={heightCanvas*0.1}
//                 fill="green"
//                 onTouchStart={() => AddBetAmount(amount)}
//                 cornerRadius={5}
//               />
//             ))}
//             {[10, 15, 25, 50, 100].map((amount, index) => (
//               <Text key={index} text={`$${amount}`}
//               x={widthCanvas*0.11+index*widthCanvas*0.11}
//               width={widthCanvas*0.1}
//               height={heightCanvas*0.1}
//               align='center'
//               verticalAlign='middle'
//               fontSize={sizeRef/30}
//               onTouchStart={() => AddBetAmount(amount)}
//               fill="white" />
//             ))}
//           </Group>

//           <Group x={widthCanvas*0.05} y={heightCanvas*0.65}  width={widthCanvas*0.8} height={heightCanvas*0.125}>
//             <Rect width={widthCanvas*0.3} height={heightCanvas*0.125} cornerRadius={5} fill="orange" onTouchStart={() => setBetThisPlayer({dog:'', line:'', pay:0})}  />
//             <Text text="CANCELAR" width={widthCanvas*0.3} height={heightCanvas*0.125} align='center' verticalAlign='middle' fontSize={sizeRef/30}fill="white" onTouchStart={() => setBetThisPlayer({dog:'', line:''})} />

//             <Rect x={widthCanvas*0.425}  y={0} width={widthCanvas*0.3} height={heightCanvas*0.125} cornerRadius={5} fill="blue" onTouchStart={() => doBetPBylayer(tripOption)} />
//             <Text text="CREAR" x={widthCanvas*0.425}  width={widthCanvas*0.3} height={heightCanvas*0.125}  align='center' verticalAlign='middle' fontSize={sizeRef/30}fill="white" onTouchStart={() => doBetPBylayer(tripOption)} />
//           </Group>
//         </Group>:<Group x={widthCanvas*0.1} y={heightCanvas*0.1} width={window.innerWidth*0.8} height={window.innerHeight*0.8}>
//           <Rect width={window.innerWidth*0.8} height={window.innerHeight*0.8} fill="white" shadowBlur={10} />
//           <Rect x={widthCanvas*0.75} y={heightCanvas*0.01} width={window.innerWidth*0.05} height={window.innerHeight*0.05} fill="white" stroke={'black'} cornerRadius={5} onTouchStart={handleClose}/>
//           <Text text="X" x={widthCanvas*0.75} y={heightCanvas*0.01}  width={window.innerWidth*0.05} height={window.innerHeight*0.05} align='center' verticalAlign='middle' fontSize={sizeRef/30} onTouchStart={handleClose}/>
//           <Text text="Nueva Jugada" fontSize={sizeRef/40} x={widthCanvas*0.01} y={heightCanvas*0.05}  width={window.innerWidth*0.8}/>
//           <Rect x={widthCanvas*0.4} y={heightCanvas*0.01} width={window.innerWidth*0.25} height={window.innerHeight*0.1} fontSize={sizeRef/40} fill={classMsg}  cornerRadius={10}/>
//           <Text text={msg} x={widthCanvas*0.4} y={heightCanvas*0.01} width={window.innerWidth*0.25} height={window.innerHeight*0.1} fontSize={sizeRef/30} fill={classMsg==='red'?'yellow': 'white'} align='center' verticalAlign='middle' />
//           <Group x={widthCanvas*0.1} y={heightCanvas*0.15} width={window.innerWidth*0.6} height={window.innerHeight*0.2}>
//           <Group x={0} y={0} width={window.innerWidth*0.6} height={window.innerHeight*0.2}>
//             <Rect x={0} y={0} width={window.innerWidth*0.6} height={window.innerHeight*0.05} fill="gray" />
//             <Text text="Jugadas" x={0} y={0} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//             <Text text="Apostado" x={window.innerWidth*0.15} y={0} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//             <Text text="Paga"     x={window.innerWidth*0.3} y={0} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//             <Text text="Ganancia"  x={window.innerWidth*0.45} y={0} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//           </Group>
//           <Group x={0} y={heightCanvas*0.01} width={window.innerWidth*0.6} height={window.innerHeight*0.2}>
//             <Text text={betThisPlayer.dog > 8 ? `P-${betThisPlayer.dog.toString().split('')[0]}-${betThisPlayer.dog.toString().split('')[1]}` : `G-${betThisPlayer.dog}`} x={0} y={window.innerHeight*0.05} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//             <Text text={`${betted}`} x={window.innerWidth*0.15} y={window.innerHeight*0.05} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40} />
//             <Text text={`${betThisPlayer.line}`} x={window.innerWidth*0.3} y={window.innerHeight*0.05} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40} />
//             <Text text={`${betThisPlayer.line * betted}`} x={window.innerWidth*0.45} y={window.innerHeight*0.05} width={window.innerWidth*0.15} height={window.innerHeight*0.05}  align='center' verticalAlign='middle' fontSize={sizeRef/40}/>
//             </Group>
//           </Group>

//           <Text text="Monto Apostar" fontSize={sizeRef/30} x={widthCanvas*0.01} y={heightCanvas*0.3}  width={window.innerWidth*0.7} height={window.innerHeight*0.05} align='center' verticalAlign='middle'/>

//           <Group  x={widthCanvas*0.125} y={heightCanvas*0.4}  width={widthCanvas*0.8} height={heightCanvas*0.2}>
//             {[10, 15, 25, 50, 100].map((amount, index) => (
//               <Rect
//                 key={index}
//                 x={index*widthCanvas*0.11}
//                 width={widthCanvas*0.1}
//                 height={heightCanvas*0.15}
//                 fill="green"
//                 onTouchStart={() => AddBetAmount(amount)}
//                 cornerRadius={5}
//               />
//             ))}
//             {[10, 15, 25, 50, 100].map((amount, index) => (
//               <Text key={index} text={`$${amount}`}
//               x={index*widthCanvas*0.11}
//               width={widthCanvas*0.1}
//               height={heightCanvas*0.15}
//               align='center'
//               verticalAlign='middle'
//               fontSize={sizeRef/30}
//               onTouchStart={() => AddBetAmount(amount)}
//               fill="white" />
//             ))}
//           </Group>

//           <Group x={widthCanvas*0.05} y={heightCanvas*0.6}  width={widthCanvas*0.8} height={heightCanvas*0.15}>
//             <Rect width={widthCanvas*0.3} height={heightCanvas*0.15} cornerRadius={5} fill="orange" onTouchStart={() => doBetPBylayer('DIRECTO')}  />
//             <Text text="CREAR DIRECTA" width={widthCanvas*0.3} height={heightCanvas*0.15} onTouchStart={() => doBetPBylayer('DIRECTO')}  align='center' verticalAlign='middle' fontSize={sizeRef/30}fill="white" />

//             <Rect x={widthCanvas*0.425}  y={0} width={widthCanvas*0.3} height={heightCanvas*0.15} cornerRadius={5} fill="blue" onTouchStart={() => doBetPBylayer(betThisPlayer.dog<=8?'CON TODOS': "PATRA Y PA'LANTE")} />
//             <Text text={betThisPlayer.dog<=8?'CON TODOS': "PATRA Y PA'LANTE"} x={widthCanvas*0.425}  width={widthCanvas*0.3} height={heightCanvas*0.15}  align='center' verticalAlign='middle' fontSize={sizeRef/30}fill="white"  onTouchStart={() => doBetPBylayer(betThisPlayer.dog<=8?'CON TODOS': "PATRA Y PA'LANTE")}/>
//           </Group>
//           </Group>}
//       </Layer>

// </Stage>
//         )

//     }} else {
//         return (
//             <div className='error'>Error</div>
//         )
//     }
// }

// export default Lines
