import React, { useCallback, useEffect, useState } from "react";
import { headers, url } from "../../../utils/const";
import { EyeFill, PenFill, XCircle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { ReportPDF } from "../../../utils/print-pdf/report-pdf";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "react-bootstrap";

function TablePoint(props) {
  let pointList = [];
  const list = props.list;
  pointList = list
    .map((z) => {
      return { ...z, status: false };
    })
    .sort((a, b) => {
      if (a.customer._id > b.customer._id) return 1;
      if (a.customer._id < b.customer._id) return -1;
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
    });
  const date = props.date;

  const [show, setShow] = useState(false);
  const [pointShow, setPointShow] = useState();
  const [disablePoint, setDisablePoint] = useState(false);
  const handleShow = (z) => {
    setPointShow(z);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  /** Disable points */
  const DisaablePoint = useCallback(async (code, state) => {
    const res = await fetch(`${url}/point`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({ code, state }),
    });
    const data = await res.json();
  });

  /**Actualizar Punto */
  const UpdatePoint = (point) => {
    if (localStorage.getItem("level") === "1") {
    } else {
      console.log("No tiene privilegios para modificar, favor contactar a un representante");
    }
  };

  const DeletePoint = (point, name) => {
    const options = {
      title: "Eliminar Punto",
      message: `${name}`,
      buttons: [
        {
          label: "Yes",
          className: "btn btn-primary ",
          onClick: async () => {
            const res = await fetch(`${url}/point/${point}`, {
              headers: headers,
              method: "delete",
            });
            const data = await res.json();
            pointList = data;
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypress: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "card col-4 justify-context-center position-absolute top-0 text-center",
    };

    confirmAlert(options);
  };
  return (
    <div className="table-responsive pt-0 mt-2 card">
      <table className="table table-sm table-borded pt-0 mt-0">
        <thead className="text-center">
          <tr>
            <th className="text-center">Num</th>
            <th>Codigo</th>
            <th>Puntos</th>
            <th>Socios</th>
            {/* <th>Direccion</th>*/}
            <th>Ciudad</th>
            <th>Tickets</th>
            <th>Apostado</th>
            <th>Gandado</th>
            <th>Pagado</th>
            <th>No_Pagado</th>
            <th>Recarga</th>
            <th>Retiros</th>
            <th>Total_en_Caja</th>
            <th>Jackpot</th>
            <th>Resultados</th>
            <th>Balance_(%)</th>
            <th>Hr_Enc</th>
            <th>Estado</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {pointList && pointList.length > 0 ? (
            pointList.map((z) => (
              <tr key={z.num}>
                <td>{pointList.indexOf(z) + 1}</td>
                <td>{z.code}</td>
                <td>
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" checked={z.state} onChange={() => DisaablePoint(z.code, z.state)} />
                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                      {z.name}
                    </label>
                  </div>
                </td>
                <td>{z.customer.name}</td>
                {/*<td>{z.address.street}</td>*/}
                <td>{z.address.city}</td>
                <td className="text-center">{z._static && z._static.amountBetted != null ? z._static.counterTicket : ""}</td>
                <td className="text-end">
                  {z._static && z._static.amountBetted != null
                    ? parseFloat(z._static.amountBetted).toLocaleString("en-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td className="text-end">
                  {z._static && z._static.amountWinned != null
                    ? parseFloat(z._static.amountWinned).toLocaleString("en-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td className="text-end">
                  {z._static && z._static.amountPaid != null
                    ? parseFloat(z._static.amountPaid).toLocaleString("en-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td className="text-end">
                  {z._static && z._static.noPaid != null
                    ? parseFloat(z._static.noPaid).toLocaleString("en-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td className="text-end">
                  {z._static && z._static.dep != null
                    ? parseFloat(z._static.dep).toLocaleString("en-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td className="text-end">
                  {z._static && z._static.ret != null
                    ? parseFloat(z._static.ret).toLocaleString("en-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td className="text-end">
                  {z._static && z._static.caja != null
                    ? parseFloat(z._static.caja).toLocaleString("en-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td className="text-end">
                  {z._static && z._static.jackpotPaid != null
                    ? parseFloat(z._static.jackpotPaid).toLocaleString("en-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td className="text-end">
                  {z._static && z._static.dif != null
                    ? parseFloat(z._static.dif).toLocaleString("en-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td className="text-end">
                  {z._static && z._static.rate != null
                    ? parseFloat(z._static.rate).toLocaleString("en-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                  %
                </td>
                <td className="text-end">{z.staticTime && z.staticTime.length > 0 && new Date(z.staticTime[z.staticTime.length - 1].date).getDate() === new Date().getDate() ? z.staticTime[z.staticTime.length - 1].startUp : ""}</td>
                <td className="text-center">
                  <span>{z.staticTime && z.staticTime.length > 0 && (new Date().getTime() - new Date(z.staticTime[z.staticTime.length - 1].last).getTime()) / 1000 <= 500 ? <span className="bg-success btn text-white m-1">ON</span> : <span className="bg-danger btn text-white m-1">OFF</span>}</span>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className="pr-1 mr-1">
                          {" "}
                          <button className="btn btn-primary m-1" onClick={() => handleShow(z)}>
                            <EyeFill />
                          </button>
                        </td>
                        <td>
                          {" "}
                          <button className="btn btn-warning m-1" disabled={localStorage.getItem("level") !== "1"} onClick={() => UpdatePoint(z)}>
                            <PenFill />
                          </button>
                        </td>
                        <td>
                          {" "}
                          <button className="btn btn-danger m-1" onClick={() => DeletePoint(z._id, z.name)} disabled={localStorage.getItem("level") != "1"}>
                            <XCircle />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr></tr>
          )}
          {
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <strong>Total</strong>
              </td>
              <td className="text-center">
                <strong>{pointList.length > 0 ? parseFloat(pointList.reduce((acum, current) => current._static.counterTicket + acum, 0)).toLocaleString('en-EN',{maximumFractionDigits:2}) : 0}</strong>
              </td>
              <td className="text-end">
                <strong>{pointList.length > 0 ? parseFloat(pointList.reduce((acum, current) => current._static.amountBetted + acum, 0)).toLocaleString('en-EN',{maximumFractionDigits:2}) : 0}</strong>
              </td>
              <td className="text-end">
                <strong>{pointList.length > 0 ? parseFloat(pointList.reduce((acum, current) => current._static.amountWinned + acum, 0)).toLocaleString('en-EN',{maximumFractionDigits:2}) : 0}</strong>
              </td>
              <td className="text-end">
                <strong>{pointList.length > 0 ? parseFloat(pointList.reduce((acum, current) => current._static.amountPaid + acum, 0)).toLocaleString('en-EN',{maximumFractionDigits:2}) : 0}</strong>
              </td>
              <td className="text-end">
                <strong>{pointList.length > 0 ? parseFloat(pointList.reduce((acum, current) => current._static.noPaid + acum, 0)).toLocaleString('en-EN',{maximumFractionDigits:2}) : 0}</strong>
              </td>
              <td className="text-end">
                {" "}
                <strong>{pointList.length > 0 ? parseFloat(pointList.reduce((acum, current) => current._static.dep + acum, 0)).toLocaleString('en-EN',{maximumFractionDigits:2}) : 0}</strong>
              </td>
              <td className="text-end">
                {" "}
                <strong>{pointList.length > 0 ? parseFloat(pointList.reduce((acum, current) => current._static.ret + acum, 0)).toLocaleString('en-EN',{maximumFractionDigits:2}) : 0}</strong>
              </td>
              <td className="text-end">
                {" "}
                <strong>{pointList.length > 0 ? parseFloat(pointList.reduce((acum, current) => current._static.caja + acum, 0)).toLocaleString('en-EN',{maximumFractionDigits:2}) : 0}</strong>
              </td>
              <td className="text-end">
                {" "}
                <strong>{pointList.length > 0 ? parseFloat(pointList.reduce((acum, current) => current._static.jackpotPaid + acum, 0)).toLocaleString('en-EN',{maximumFractionDigits:2}) : 0}</strong>
              </td>
              <td className="text-end">
                <strong>{pointList.length > 0 ? parseFloat(pointList.reduce((acum, current) => current._static.dif + acum, 0)).toLocaleString('en-EN',{maximumFractionDigits:2}) : 0}</strong>
              </td>
              <td className="text-end">
                <strong>{pointList.length > 0 ? ((pointList.reduce((acum, current) => current._static.dif + acum, 0) * 100) / pointList.reduce((acum, current) => current._static.amountBetted + acum, 0)).toFixed(2) : 0}%</strong>
              </td>
              <td></td>
              <td></td>
              <td>
                <button className="btn btn-warning" onClick={() => ReportPDF(pointList, date)}>
                  Imprimir Reporte
                </button>
              </td>
            </tr>
          }
        </tbody>
      </table>
      {/**Modal */}
      <Modal show={show} className="p-0 m-0 bg-dark" centered>
        <ModalHeader>
          <h3>Reporte de Venta</h3>
          <button className="btn btn-close" onClick={handleClose}></button>
        </ModalHeader>
        <ModalBody>
          <div className="container m-3 p-3">
            <div className="col-12 row justify-content-between m-0 p-0">
              <div className="col-6 fs-4  border border-dark">Punto</div>
              <div className="col-6 fs-3  border border-dark m-0 p-0 text-center">{pointShow ? pointShow.name : ""}</div>
            </div>
            <div className="col-12 row justify-content-between border border-dark  m-0 p-0">
              <div className="col-6 fs-4 border border-dark">Fecha: </div>
              <div className="col-6 fs-4 border border-dark text-center">{pointShow ? (!date || !date.init || date.init === "" ? `${new Date().toLocaleDateString("es-ES")} ${new Date().getHours().toString().padStart(2, "0")}:${new Date().getMinutes().toString().padStart(2, "0")}:${new Date().getSeconds().toString().padStart(2, "0")}` : `${new Date(date.init).toLocaleDateString("es-ES")} - ${new Date(date.end).toLocaleDateString("es-ES")}`) : ""}</div>
            </div>
            <div className="col-12 row justify-content-between border border-dark  m-0 p-0">
              <div className="col-6 fs-4 border border-dark">Tickets:</div> <div className="col-6 fs-4 border border-dark text-center">{pointShow ? pointShow._static.counterTicket.toLocaleString('en-EN',{maximumFractionDigits:2}) : ""}</div>
            </div>
            <div className="col-12 row justify-content-between border border-dark  m-0 p-0">
              <div className="col-6 fs-4 border border-dark">Monto APostado:</div> <div className="col-6 fs-4 border border-dark text-center">{pointShow ? pointShow._static.amountBetted.toLocaleString('en-EN',{maximumFractionDigits:2}) : ""}</div>
            </div>
            <div className="col-12 row justify-content-between border border-dark  m-0 p-0">
              <div className="col-6 fs-4 border border-dark">Monto Pagado: </div>
              <div className="col-6 fs-4 border border-dark text-center">{pointShow ? pointShow._static.amountPaid.toLocaleString('en-EN',{maximumFractionDigits:2}) : ""}</div>
            </div>
            <div className="col-12 row justify-content-between border border-dark  m-0 p-0">
              <div className="col-6 fs-4 border border-dark">Jackpots: </div>
              <div className="col-6 fs-4 border border-dark text-center">{pointShow ? pointShow._static.jackpotPaid.toLocaleString('en-EN',{maximumFractionDigits:2}) : ""}</div>
            </div>
            <div className="col-12 row justify-content-between border border-dark  m-0 p-0">
              <div className="col-6 fs-4 border border-dark">Balance: </div>
              <div className="col-6 fs-4 border border-dark text-center">{pointShow ? pointShow._static.dif.toLocaleString('en-EN',{maximumFractionDigits:2}) : ""}</div>
            </div>
            <div className="col-12 row justify-content-between border border-dark  m-0 p-0">
              <div className="col-6 fs-4 border border-dark">Porciento: </div>
              <div className="col-6 fs-4 border border-dark text-center">{pointShow ? `${((pointShow._static.dif * 100) / pointShow._static.amountBetted).toFixed(0)}%` : ""}</div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
}

export default TablePoint;
