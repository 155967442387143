import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { DoorOpen, DoorOpenFill } from 'react-bootstrap-icons';
import "react-datepicker/dist/react-datepicker.css";
import { headers, url } from '../../utils/const';
import './ventas.css';
import Tickets from '../tickets/tickets';

function Venta() {
  const [ventas, setVentas] = useState({
    betted: 0,
    winned: 0,
    paid: 0,
    balance: 0,
    deposito: 0,
    retiro: 0,
    balancePlayer:0,
    point: '',
    tickets: [{
      num: 0,
      betted: 0,
      date: '',
      total:0,
      delete: false,
      winner: {
        jackpot: { value: 0 },
        state: false,
        factor: 1,
        amountWinner: 0,
        amountToPay: 0,
        paid: false,
      }
    }]
  })
  const [formDate, setFormDate] = useState({ init: new Date(), end: new Date() })
useEffect(() => GetVentas, []);
  /**Funcion para buscar las apuesta en el invtervalo  */
  const GetVentas = () => {
    fetch(`${url}/run/venta`, {
      method: "post",
      headers: headers,
      body: JSON.stringify(formDate),
    })
      .then((res) => res.json())
      .then((data) => {
        setVentas(data);
      });
  };
  /**Imprimir Reporte de Venta */
  const ToPrint = () => {
    window.print()
  }
  return (
    <div>
      <div className='yPrint'>
        {
          <div>
            <div className='fs-3 text-center'><strong>REPORTE DE VENTA</strong></div>
            <div className='fs-3 text-center border border-dark'><strong>{ventas.point}</strong></div>
            <div>
              <div className='col-12 fs-5'> {formDate.init.toLocaleDateString('es-ES') === formDate.end.toLocaleDateString('es-ES') ?
                `Fecha: ${formDate.init.toLocaleDateString('es-ES')}  Hora:${formDate.init.getHours()+':'+formDate.init.getMinutes()+':'+formDate.init.getSeconds()}` : `${formDate.init.toLocaleDateString('es-ES')} al ${formDate.end.toLocaleDateString('es-ES')}`
              }</div><br />
              {typeof (ventas) == 'object' ?
                <div>
                  <div className='col-12 fs-5'>Apostado: <strong>RD:$ {parseInt(ventas.betted).toLocaleString('en-EN')}</strong></div><br />
                  <div className='col-12 fs-5'>Ganador: <strong>RD:$ {parseInt(ventas.winned).toLocaleString('en-EN')}</strong></div><br />
                  <div className='col-12 fs-5'>Pagado: <strong>RD:$ {parseInt(ventas.paid).toLocaleString('en-EN')}</strong></div><br />
                  <div className='col-12 fs-3 border border-dark'>Balance: <strong>RD:$ {parseInt(ventas.balance).toLocaleString('en-EN')}</strong></div>
                  <div className='col-12 fs-5'>Recargas: <strong>RD:$ {parseInt(ventas.deposito).toLocaleString('en-EN')}</strong></div><br />
                  <div className='col-12 fs-5'>Cobros: <strong>RD:$ {parseInt(ventas.retiro).toLocaleString('en-EN')}</strong></div><br />
                  <div className='col-12 fs-5'>Balance: <strong>RD:$ {parseInt(ventas.balancePlayer).toLocaleString('en-EN')}</strong></div><br />
                  <div className='col-12 fs-3 border border-dark'>Totol en Caja: <strong>RD:$ {parseInt(ventas.total).toLocaleString('en-EN')}</strong></div><br />

                </div>
                : <div>{ventas}</div>
              }
            </div>
          </div>
        }
      </div>
      <header className='noPrint'>
        <nav>
          <div className="nvegador">
            <Link className='vol venta' to="/bet">VENTA</Link>
            <Link to="/line" className='vol lin'>LINEA</Link>
            <Link className='vol res' to='/history'>RESULTADOS</Link>
            <Link className='vol volvent' to='/venta'>VOLUMEN DE VENTAS</Link>
            <Link className='vol res' to='/player'>JUGADORES</Link>
            <button className="boton_menu bg-danger" onClick={() => localStorage.clear()}><DoorOpenFill /></button>
          </div>
        </nav>
      </header>

      <div className='position-relative top-50 start-50 translate-middle card p-3 m-3 col-10 row noPrint'>

        <div className='col-12 text-center card-header'><h1>Reporte de Venta</h1></div>
        <div className=' col-12'>
          <hr />
          <div className='card-body row col-12 bg'>
            <div className='col-4'>
              <DatePicker className='form-control' selected={formDate.init} onSelect={(date) => setFormDate((value) => {
                return {
                  ...value, init: date
                }
              })} />
            </div>
            <div className='col-4'>
              <DatePicker className='form-control' selected={formDate.end} onSelect={(date) => setFormDate((value) => {
                return {
                  ...value, end: date
                }
              })} />
            </div>
            <div className='col-4 pb-3 mb-3'>
              <input className='btn btn-danger form-control' type="button" value='Buscar'
                onClick={GetVentas}
              />
            </div>
            <div className='col-12 row p-0 m-0'>
              {typeof (ventas) == 'object' ?
                <div className='col-12 row pt-1 mt-1'>
                  <div className='col-3 fs-5'>Monto Apostado: <strong>RD:$ {parseInt(ventas.betted).toLocaleString('en-EN')}</strong></div>
                  <div className='col-3 fs-5'>Monto Ganado: <strong>RD:$ {parseInt(ventas.winned).toLocaleString('en-EN')}</strong></div>
                  <div className='col-3 fs-5'>Monto Pagado: <strong>RD:$ {parseInt(ventas.paid).toLocaleString('en-EN')}</strong></div>
                  <div className='col-3 fs-5'>Balance: <strong>RD:$ {parseInt(ventas.balance).toLocaleString('en-EN')}</strong></div>
                  <div className='col-3 fs-5'>Recargas: <strong>RD:$ {parseInt(ventas.deposito).toLocaleString('en-EN')}</strong></div>
                  <div className='col-3 fs-5'>Retiros: <strong>RD:$ {parseInt(ventas.retiro).toLocaleString('en-EN')}</strong></div>
                  <div className='col-3 fs-5'>Balance: <strong>RD:$ {parseInt(ventas.balancePlayer).toLocaleString('en-EN')}</strong></div>
                  <div className='col-3 fs-5 bg-warning'>Total en Caja: <strong className='fs-5'>RD:$ {parseInt(ventas.total).toLocaleString('en-EN')}</strong></div>
                </div> : <div>{ventas}</div>
              }
              <div className='col-12 fs-5 row'>
                <div className='col-4 p-3 m-3'><input type="button" value="Print" onClick={ToPrint} className="btn btn-primary form-control" /></div>
                <div className='col-6'>{ventas.tickets.length>1 &&<Tickets tickets={ventas.tickets}/>}</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Venta