import React, { useEffect, useState, useRef } from "react";
import "./racing.css";
import { socket } from "../../io";
import { heightCanvas, sizeRef, url, widthCanvas } from "../../utils/const";
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Rect,
  Group,
  Text,
} from "react-konva";
import logo from "../../assets/logo.png";

const Racing = (props) => {
  const [isVideo, setIsVideo] = useState(false);
  const [showMsn, setShowMsn] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [startup, setStartup] = useState(new Date().getTime());
  const [imgLogo, setImgLogo] = useState();
  const videoRef = useRef(null);
  const konvaImageRef = useRef(null);

  useEffect(() => {
    const img = new window.Image();
    img.src = logo;
    img.onload = () => setImgLogo(img);
  }, []);

  useEffect(() => {
    const videoElement = document.createElement("video");
    videoElement.src = videoSrc;
    videoElement.muted = true;
    videoElement.playsInline = true;

    videoElement.addEventListener("loadeddata", () => {
      const imageNode = konvaImageRef.current;
      imageNode.image(videoElement);
      videoElement.play();
    });

    videoElement.addEventListener("play", () => {
      const animate = () => {
        if (videoElement.paused || videoElement.ended) return;
        konvaImageRef.current.getLayer().batchDraw();
        requestAnimationFrame(animate);
      };
      animate();
    });

    // Guardar la referencia del nuevo video
    videoRef.current = videoElement;

    return () => {
      // Liberar recursos del video anterior
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.src = "";
        videoRef.current.load();
      }
    };
  }, [videoSrc]);

  useEffect(() => {
    socket.on("video", (data) => {
      if (new Date().getTime() - startup > 10000) {
        setShowMsn(true);
      }
      if (!isVideo) {
        setVideoSrc(`../../convertido/${data.video}`);
        if (videoSrc) setIsVideo(true);
        setShowMsn(false);
      }
    });
  }, [videoSrc, startup, isVideo, showMsn]);

  return (
    <Stage width={widthCanvas} height={heightCanvas}>
      <Layer>
        {!showMsn || (videoSrc !== "" && !videoSrc.includes("x")) ? (
          <Group x={0} y={0} width={widthCanvas} height={heightCanvas}>
            <KonvaImage
              ref={konvaImageRef}
              x={0}
              y={0}
              width={widthCanvas}
              height={heightCanvas}
            />
            <KonvaImage
              image={imgLogo}
              x={widthCanvas * 0.8}
              y={0}
              width={widthCanvas * 0.15}
              height={heightCanvas * 0.07}
              visible={true}
            />
          </Group>
        ) : (
          <Group
            x={widthCanvas / 4}
            y={heightCanvas / 4}
            fill="blue"
            width={widthCanvas / 2}
            height={heightCanvas / 2}
          >
            <Rect
              x={0}
              y={0}
              fill="blue"
              width={widthCanvas / 2}
              height={heightCanvas / 2}
              cornerRadius={10}
              stroke={"red"}
              strokeWidth={10}
            />
            <Text
              text="NOTICE/AVISO"
              x={0}
              y={0}
              fill="white"
              width={widthCanvas / 2}
              height={heightCanvas / 6}
              align="center"
              verticalAlign="middle"
              fontSize={heightCanvas * 0.1}
              fontFamily="Arial Dark"
            />
            <Text
              text="Error in transmission, the race is active..."
              x={0}
              y={heightCanvas / 8}
              fill="white"
              width={widthCanvas / 2}
              height={heightCanvas / 6}
              align="center"
              verticalAlign="middle"
              fontSize={heightCanvas * 0.05}
              fontFamily="Arial Dark"
            />
            <Text
              text="Error en transmisión, la carrera está activa..., "
              x={0}
              y={heightCanvas / 4}
              fill="white"
              width={widthCanvas / 2}
              height={heightCanvas / 6}
              align="center"
              verticalAlign="middle"
              fontSize={heightCanvas * 0.05}
              fontFamily="Arial Dark"
            />
          </Group>
        )}
      </Layer>
    </Stage>
  );
};

export default Racing;
