export const CalcularApostado = (betted, num) => {
  let pale = [];
  let bodyBet = [];
  const dog = betted.dog;
  const type = betted.type;
  const amount = betted.amount;
  const first = dog.firt
    .split("")
    .map((z) => parseInt(z))
    .filter((item) => item != "");
  const second = dog.second
    .split("")
    .map((z) => parseInt(z))
    .filter((item) => item != "");
  const third = dog.third
    .split("")
    .map((z) => parseInt(z))
    .filter((item) => item != "");
  if (type.includes("DIRECTO")) {
    /**Directo Primer Lugar */
    if (second.length === 0 && first.length > 0 && third.length === 0) {
      bodyBet = first.map((z) => ({ dog: { firt: z, second: "", third: "" }, run: betted.run, amount: amount, type: type }));
      return { bodyBet, amount: first.length * amount };
    } else if (second.length > 0 && first.length > 0 && third.length === 0) {
      first.forEach((item) => {
        const firtPale = second.map((z) => {
          if (z != item) return `${item}-${z}`;
          return "";
        });
        pale = [...new Set(pale.concat(firtPale).filter((item) => item != ""))];
        bodyBet = pale.map((z) => ({ dog: { firt: z.split("-")[0], second: z.split("-")[1], third: "" }, run: betted.run, amount: amount, type: type }));
      });
      return { bodyBet, amount: pale.length * amount };
    }
  } else if (type.includes("PATRA")) {
    if (second.length > 0 && first.length > 0 && third.length == 0) {
      first.forEach((item) => {
        const firtPale = second.map((z) => {
          if (z != item) return `${item}-${z}`;
          return "";
        });
        pale = [...new Set(pale.concat(firtPale).filter((item) => item != ""))];
      });
      second.forEach((item) => {
        const secondPale = first.map((z) => {
          if (z != item) return `${item}-${z}`;
          return "";
        });
        pale = [...new Set(pale.concat(secondPale).filter((item) => item != ""))];
      });
      bodyBet = pale.map((z) => ({ dog: { firt: z.split("-")[0], second: z.split("-")[1], third: "" }, run: betted.run, amount: amount, type: type }));
      return { bodyBet, amount: pale.length * amount };
    } else {
      return 0;
    }
  } else if (type.includes("CON TODO")) {
    const otherDog = [];
    for (let i = 1; i <= num; i++) {
      otherDog.push(i);
    }
    if (first.length > 0) {
      first.forEach((item) => {
        const firtPale = otherDog.map((z) => {
          if (z != item) return `${item}-${z}`;
          return "";
        });
        pale = [...new Set(pale.concat(firtPale).filter((item) => item != ""))];
      });
      otherDog.forEach((item) => {
        const secondPale = first.map((z) => {
          if (z != item) return `${item}-${z}`;
          return "";
        });
        pale = [...new Set(pale.concat(secondPale).filter((item) => item != ""))];
      });
    }
    bodyBet = pale.map((z) => ({ dog: { firt: z.split("-")[0], second: z.split("-")[1], third: "" }, run: betted.run, amount: amount, type: type }));
    return { bodyBet, amount: pale.length * amount };
  } else if (type.includes("EXACT") || type.includes("COLOC")) {
    if (second.length > 0 && first.length > 0 && third.length > 0) {
      first.forEach((x) => {
        second.forEach((y) => {
          if (x !== y) {
            third.forEach((z) => {
              if (x !== z && y !== z) {
                bodyBet.push({ dog: { firt: x, second: y, third: z }, run: betted.run, amount: amount, type: type });
              }
            });
          }
        });
      });
      return { bodyBet, amount: bodyBet.length * amount };
    }
  } else {
    alert("Jugada Incorrecta");
  }
};
